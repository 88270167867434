<template>
  <AggreementContent title="ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ">
    <p>Перед началом использования Сервиса, пожалуйста, ознакомьтесь с условиями настоящего Пользовательского соглашения. Факт использования Сервиса любым способом (как намеренным, так и непреднамеренным) признается акцептом, то есть полным и безоговорочным согласием Пользователя, использовать Сервис на изложенных ниже условиях. Использование Сервиса допустимо исключительно на условиях настоящего Пользовательского соглашения. Если Пользователь не принимает условия Пользовательского соглашения в полном объеме, он не вправе использовать Сервис в каких-либо целях.</p>

<br><p><b>1. Термины и определения</b></p>
<p>1.1. Сервис - программное обеспечение, функционирующее на ресурсах Оператора </p>
<p>(<a href="https://kedo.gk-osnova.ru/" target="_blank">https://kedo.gk-osnova.ru/</a>, <a href="https://start.gk-osnova.ru/" target="_blank">https://start.gk-osnova.ru/</a>) для ведения кадрового электронного документооборота. Сервис позволяет осуществлять документооборот в электронном виде, создавать УНЭП, подписывать Пользователями Сервиса предложенной документации Клиента/Оператора и использовать иной функционал, доступный в Сервисе.</p>
<p>1.2. Клиент - индивидуальный предприниматель или юридическое лицо, имеющее трудовые или гражданско-правовые правоотношения с Пользователем, и предавшее Оператору поручение на обработку персональных данных Пользователей.</p>
<p>1.3. Оператор - АО «ГК «ОСНОВА» (ИНН: 9715264590; ОГРН: 1167746614530 (далее - Оператор), зарегистрированному по адресу: 142702, Московская обл., Ленинский р-н, Видное г., рабочий поселок Бутово, территория жилой комплекс Бутово-Парк, дом № 18, корпус 1, пом. 59, организующий и (или) осуществляющий обработку персональных данных, а также определяющий цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
<p>1.4. Пользователь - любое лицо, использующее Сервис и имеющее с Клиентом/Оператором гражданско-правовые или трудовые правоотношения, в частности физическое лицо, индивидуальный предприниматель или самозанятый.</p>
<p>1.5. Электронная подпись - информация в электронной форме, которая присоединена к другой информации в электронной форме (подписываемой информации) или иным образом связана с такой информацией и которая используется для определения лица, подписывающего информацию, а также подтверждает волеизъявление лица/лиц на подписание документа.</p>
<p>1.6. Усиленная неквалифицированная электронная подпись (УНЭП) - электронная подпись в виде криптографического шифра, записанного в защищенном облачном хранилище. УНЭП идентифицирует личность владельца, подтверждает факт формирования подписи определенным лицом и неизменность документа с момента подписания. Пользователь Сервиса признает, что электронные документы, подписанные в Сервисе посредством УНЭП, признаются равнозначными документам на бумажном носителе, подписанным собственноручно. В том числе данные условия распространяются на электронные документы, подписываемые в рамках гражданско-правовых правоотношений между Клиентом/Оператором Пользователем, выступающим в качестве индивидуального предпринимателя или самозанятого.</p>
<p>1.7. Простая электронная подпись (ПЭП) - электронная подпись, которая посредством использования кодов, паролей иных средств подтверждает факт формирования электронной подписи определенным лицом. По смыслу настоящего Соглашения под ПЭП понимается логин и пароль Пользователя при входе в Сервис, а также одноразовый пароль, передаваемый Пользователю посредством SMS на его номер мобильного телефона.</p>
<p>1.8. КОД - это одноразовый набор произвольных чисел, приходящий посредством СМС сообщения на номер мобильного телефона Пользователя, который предназначен для входа в Сервис, подписания Пользователем электронных документов посредством ПЭП (заявление на выпуск УНЭП) и подтверждения лицом, подписывающим электронный документ, операции по созданию УНЭП (подписание кадровых электронных документов). Использование Сервиса с применением КОДА для подписания документа ПЭП является надлежащим и достаточным способом подтверждения личности Пользователя без документа, удостоверяющего личность.</p>
<p>1.9. Удостоверяющий центр (УЦ)- юридическое лицо, индивидуальный предприниматель либо государственный орган или орган местного самоуправления, осуществляющие функции по созданию и выдаче сертификатов ключей проверки электронных подписей.</p>

<br><p><b>2.	Общие положения</b></p>
<p>2.1. Настоящее Пользовательское соглашение регулирует использование Пользователями Сервиса на изложенных ниже условиях.</p>
<p>2.2. Пользователь обязуется использовать Сервис только в пределах тех прав и теми способами, которые предусмотрены настоящим Пользовательским соглашением, а также предложены в Сервисе.</p>
<p>2.3. По настоящему Пользовательскому соглашению Оператор предоставляет Пользователю Сервиса доступ к Сервису, а также по запросу Пользователя Сервиса обеспечивает выпуск УНЭП с использованием УЦ, для подписания документации между Клиентом/Оператором и Пользователем.</p>
<p>2.4. Доступ к Сервису предоставляется Пользователю Сервиса безвозмездно для использования по прямому назначению.</p>

<br><p><b>3.	Порядок использования Сервиса</b></p>
<p>3.1. Для начала использования Сервиса Пользователем Оператор направляет Пользователю приглашение смс- сообщением.</p>
<p>3.2. Приглашение содержит ссылку для авторизации и подтверждения своей учетной записи (личного кабинета) Пользователя Сервиса.</p>
<p>3.3. В случае изменения сведений о номере мобильного телефона Пользователь принимает на себя обязательства незамедлительно сообщить Оператору об изменении указанных сведений посредством их направления на электронный адрес: <a href="mailto:dpers@gk-osnova.ru">dpers@gk-osnova.ru</a>. Пользователь самостоятельно несет риски неблагоприятных последствий, связанных с неполучением (несвоевременным получением) уведомлений по причине отсутствия у Оператора сведений об актуальном номере мобильного телефона.</p>
<p>3.4. В личном кабинете Пользователю доступен предлагаемый функционал Сервиса, в том числе возможность выпуска УНЭП (посредством отправления запроса Пользователем в статусе “Кадровик”), подписания документов с Клиентом/Оператором, а также история подписанной Пользователем с Клиентом/Оператором в Сервисе документации.</p>
<p>3.5. Пользователь соглашается на обмен электронными документами и их подписание с помощью УНЭП в Сервисе, как для внутреннего использования (при наличии трудовых правоотношений с Клиентом/ Оператором), так и для внешнего (для гражданско-правовых правоотношений Пользователя-индивидуального предпринимателя или самозанятого с Клиентом /Оператором). Стороны признают юридическую силу таких электронных документов в соответствии с действующим законодательством РФ, а также условиями настоящего Пользовательского соглашения.</p>
<p>3.6. Каждому Пользователю Сервиса, подтвердившему свою учетную запись в Сервисе, для электронного документооборота с Клиентом предоставляется возможность выпустить УНЭП с помощью Сервиса при участии удостоверяющего центра.</p>
<p>3.7. Пользователь Сервиса подписывает заявление на выпуск УНЭП Простой электронной подписью путем подтверждения кода по SMS</p>
<p>3.8. При выпуске УНЭП для Пользователя Оператор осуществляет идентификацию (подтверждение личности) Пользователя на основании доверенности от удостоверяющего центра и выступает доверенным лицом по смыслу Федерального закона "Об электронной подписи" от 06.04.2011 № 63-ФЗ, так же Сервис предоставляет возможность пройти удалённую идентификацию с помощью ЕПГУ.</p>
<p>3.9. Для продолжения процедуры выпуска УНЭП, Пользователь подтверждает по полученной ссылке (смс сообщением на номер мобильного телефона) выпуск УНЭП.</p>
<p>3.10. Для завершения процедуры выпуска УНЭП, а также возможности подписывать электронные документы в Сервисе, Пользователь посредством ПЭП подтверждает выпущенный сертификат УНЭП.</p>
<p>3.11. Электронные документы (заявление Пользователя на выпуск УНЭП, подтверждение сертификата УНЭП и иные документы/действия) для выпуска УНЭП с целью ведения электронного документооборота в Сервисе, подписанные Пользователем ПЭП, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью, а сведения, содержащиеся в таком документе, признаются подлинными и достоверными, а также</p>
<ul>
<li>имеют такую же юридическую силу, как и подписанные собственноручно документы на бумажном носителе, и влекут предусмотренные для указанных документов правовые последствия;</li>
<li>не могут быть оспорены или отрицаться Сторонами и третьими лицами или быть признанными недействительными только на том основании, что они были подписаны с использованием Сервиса или оформлены в виде электронного документа;</li>
<li>могут быть представлены в качестве доказательств, равносильных письменным доказательствам, в порядке, предусмотренном законодательством Российской Федерации, при этом допустимость таких доказательств не может отрицаться только на том основании, что они представлены в виде электронных документов или их копий на бумажном носителе;</li>
<li>наличие в документе Простой электронной подписи Пользователя подразумевает, что документ направлен и подписан им в надлежащем порядке.</li>
</ul>
<p>Для использования Сервиса с целью подписания указанных документов ПЭП, Пользователь самостоятельно обеспечивает подключение к каналам электронной, в т.ч. мобильной связи и/или поддержку необходимых функций, в т.ч. функций SMS, на своих устройствах (телефоне, смартфоне, планшете, компьютере и т.п.) у своего оператора сотовой связи.</p>
<p>3.12. Оператор самостоятельно не осуществляет создание ключа электронной подписи и ключа проверки электронной̆ подписи, выпуск неквалифицированного сертификата ключа проверки носителей̆ ключевой̆ информации, содержащих на себе ключевую информацию, а также другие виды деятельности, предусмотренные Постановлением Правительства РФ от 16 апреля 2012 г. № 313 и не несет ответственность за действия удостоверяющего центра.</p>
<p>3.13. Подписанная УНЭП документация хранится на Сервисе.</p>

<br><p><b>4.	Права и обязанности Сторон</b></p>
<p>4.1. Оператор вправе:</p>
<p>4.1.1. Осуществлять модификацию Сервиса в целях улучшения его функционирования, а также добавления дополнительного функционала и возможностей (фича) в любое время без предварительного уведомления Пользователя Сервиса.</p>
<p>4.1.2. Осуществлять профилактические и технические работы по поддержанию функциональности Сервиса, на время проведения которых Оператор/Пользователь не смогут пользоваться Сервисом.</p>
<p>4.1.3. Незамедлительно блокировать/приостанавливать доступ Пользователя Сервиса к Сервису в случаях нарушения Пользователем положений настоящего Пользовательского соглашения.</p>
<p>4.2. Пользователь Сервиса гарантирует:</p>
<p>4.2.1. Следовать всему применимому законодательству;</p>
<p>4.2.2. При выпуске УНЭП предоставлять достоверные и актуальные личные данные, в противном случае вся ответственность возлагается на Пользователя Сервиса.</p>
<p>4.2.3. В случае изменения применимого законодательства, которое может повлиять на Пользовательское соглашение, или если Оператор предполагает, что он не может выполнять свои обязательства по Пользовательскому соглашению из-за этих изменений, Оператор вправе немедленно прекратить действие Пользовательского соглашения в одностороннем порядке. При этом Пользователь освобождает Оператора от всех обязательств по Пользовательскому соглашению.</p>
<p>4.2.4. Обеспечивать конфиденциальность любых электронных подписей̆, используемых по смыслу настоящего Пользовательского соглашения, в частности не допускать использование принадлежащих ему электронных подписей̆ без его согласия;</p>
<p>4.2.5. Уведомлять удостоверяющий̆ центр, выдавший̆ электронную подпись, и иных участников электронного документооборота о нарушении конфиденциальности электронной̆ подписи в течение не более чем одного рабочего дня со дня получения информации о таком нарушении;</p>
<p>4.2.6. Не использовать электронную подпись при наличии оснований полагать, что ее конфиденциальность нарушена;</p>
<p>4.2.7. Использовать для выпуска УНЭП, ПЭП, согласно настоящему Пользовательскому соглашению, а также соблюдать требования Федерального закона от 06.04.2011 № 63-ФЗ «Об электронной̆ подписи»;</p>
<p>4.2.8. Соблюдать условия использования электронных подписей̆, порядок проверки электронных подписей̆, правила обращения с такими подписями, установленные нормативными документами удостоверяющих центров, выдавших сертификаты.</p>
<p>4.3. Пользователю запрещено:</p>
<p>4.3.1. Сдавать в аренду, лизинг, передавать, перепродавать, дарить, обменивать, распространять или иным образом использовать Сервис или его копии, а также распространять информацию о намерении совершить действия, перечисленные выше, Пользователем или любыми другими третьими лицами.</p>
<p>4.3.2. Изменять, объединять, адаптировать, декомпилировать, дизассемблировать, модифицировать, осуществлять перевод на другие языки или любым иным образом изменять Сервис или любые его компоненты.</p>
<p>4.3.3. Создавать производные продукты на основе Сервиса.</p>
<p>4.3.4. Совершать любые действия, которые могут повредить, отключить, перегружать Сервис или нарушить функционирование Сервиса любым способом.</p>
<p>4.3.5. Использовать Сервис любым способом, который̆ нарушает настоящее Пользовательское соглашение, любое применимое местное, национальное или международное законодательство, любые другие правила и политики.</p>
<p>4.3.6. Использовать Сервис для умышленного нарушения законодательства и нарушения нормальной̆ работы Сервиса.</p>
<p>4.3.7. Загружать в Сервис информацию, содержащую сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность (биометрические персональные данные).</p>
<p>4.3.8. Загружать в Сервис информацию, содержащую сведения касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни (специальные персональные данные).</p>

<br><p><b>5.	Конфиденциальность</b></p>
<p>5.1. Оператор может использовать персональную информацию пользователей Сервиса, на основании Политики конфиденциальности, и в связи с предоставлением им услуг и соответствующей информации, ответов на запросы.</p>

<br><p><b>6.	Ограничение ответственности</b></p>
<p>6.1. Сервис предоставляется «как есть», Оператор не несет ответственности за точное соответствие Сервиса требованиям Пользователя.</p>
<p>6.2. Оператор не может гарантировать бесперебойный и безотказный доступ к Сервису и ни при каких условиях не несет ответственность за возможные убытки, возникшие у Пользователей в связи с использованием Сервиса.</p>
<p>6.3. Пользователь Сервиса самостоятельно несет ответственность за сохранность и конфиденциальность регистрационных данных (логин и пароль) своей учетной записи. Пользователь Сервиса самостоятельно несет ответственность перед третьими лицами за все действия, совершенные третьими лицами с использованием логина и пароля Пользователя Сервиса.</p>

<br><p><b>7.	Прочие условия</b></p>
<p>7.1 Настоящее Соглашение может быть изменено Оператором без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения находится на странице по адресу: </p>
<p><a href="https://kedo.gk-osnova.ru/" target="_blank">https://kedo.gk-osnova.ru/</a>, <a href="https://start.gk-osnova.ru/" target="_blank">https://start.gk-osnova.ru/</a>, если в Соглашении не указано иное.</p>
<p>7.2 Настоящее Пользовательское соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. По всем вопросам, не урегулированным настоящим Пользовательским соглашением, стороны руководствуются действующим законодательством Российской Федерации.</p>
<p>7.3 Если по каким-либо причинам одно или несколько условий настоящего Пользовательского соглашения будут признаны недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость остальных условий Соглашения.</p>
<p>7.4 Настоящее Соглашение может быть в любой момент расторгнуто Пользователем посредством Сервиса или направления уведомления о расторжении в письменной форме на электронный адрес <a href="mailto:dpers@gk-osnova.ru">dpers@gk-osnova.ru</a> Оператору. Оператор вправе расторгнуть Соглашение в одностороннем порядке, уведомив Пользователя о расторжении посредством Сервиса.</p>
<p>7.5 В случае если Оператором были внесены какие-либо изменения в настоящее Соглашение, с которыми Пользователь не согласен, он обязан прекратить использование Сервиса.</p>
<p>7.6 Все вопросы, предложения и/или претензии, связанные с использованием/ невозможностью использования Сервиса, а также возможным нарушением законодательства и/или прав третьих лиц, должны направляться по адресу: <a href="mailto:dpers@gk-osnova.ru">dpers@gk-osnova.ru</a>.</p>
  </AggreementContent>
</template>

<script>
import AggreementContent from '@/components/AggreementContent.vue'

export default {
  components: {
    AggreementContent
  }
}
</script>
