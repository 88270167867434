<template>
  <div class="documents-page page-bottom">
    <ContainerWrapper>
      <div class="documents-page__content">
        <DocumentsList :list="list"/>
        <div class="documents-page__more" v-if="!endOfList">
          <UiButton :disabled="isLoad" @click="getMore" >Загрузить еще</UiButton>
        </div>
      </div>
    </ContainerWrapper>
  </div>
</template>

<script>
import { inject, onMounted, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import DocumentsList from '@/components/DocumentsList.vue'
import ContainerWrapper from '@/components/ContainerWrapper.vue'
import UiButton from '@/components/ui/UiButton.vue'
export default {
  components: {
    DocumentsList,
    ContainerWrapper,
    UiButton
  },
  setup () {
    const api = inject('apiMethods')
    const list = ref([])
    const isLoad = ref(false)
    const endOfList = ref(false)
    const store = useStore()
    const filter = computed(() => store.getters.getDocumentFilter)
    onMounted(() => {
      watch(filter, (newVal) => {
        const documents = api.documents.getList(
          newVal.page,
          newVal.limit,
          newVal.offset,
          newVal.statuses,
          newVal.docTypes,
          newVal.sortField,
          newVal.sortDir,
          newVal.legals
        )
        isLoad.value = true
        documents.then((data) => {
          if (data.success) {
            if (filter.value.page == 1) {
              list.value = data.data.documents
            } else {
              list.value = list.value.concat(data.data.documents)
            }
            if (list.value.length < newVal.limit * newVal.page ) {
              endOfList.value = true
            } else {
              endOfList.value = false
            }
          }
        })
        .finally(() => {
          isLoad.value = false
        })
      })
      const documents = api.documents.getList(
          filter.value.page,
          filter.value.limit,
          filter.value.offset,
          filter.value.statuses,
          filter.value.docTypes,
          filter.value.sortField,
          filter.value.sortDir,
          filter.value.legals
        )
      isLoad.value = true
      documents.then((data) => {
        if (data.success) {
          if (filter.value.page == 1) {
            list.value = data.data.documents
          } else {
            list.value = list.value.append(data.data.documents)
          }
        }
      }).finally(() => {
        isLoad.value = false
      })
    })
    return {
      list,
      endOfList,
      isLoad,
      getMore () {
        store.commit('setDocumentFilter', {page: filter.value.page + 1})
      }
    }
  }
}
</script>
<style lang="scss">
.documents-page {
  padding-top: 40px;
  &__more {
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
