import { send } from './common.js'
export default {
  getMasterUser: function () {
    return send({
      url: 'kedo/master-user',
      method: 'GET',
    })
  },
  getUsersList: function () {
    return send({
      url: 'kedo/users-list',
      method: 'GET',
    })
  },
}
