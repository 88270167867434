<template>
  <div class="document-item">
    <div class="document-item__top">
      <div class="document-item__title">
        <div class="document-item__title-content">{{ name }}</div>
        <div :class="['tag-wrapper','document-item__status', 'document-item__status-' + status]">
          {{ statusLocalize }} {{ status == 'signed' ? tagDate : '' }}
          <div v-if="status == 'signed'" class="document-item__signed-date">
            {{ tooltipDate }}
          </div>
        </div>
      </div>
      <div class="document-item__props">
        <div class="document-item__props-item">
          <div class="document-item__props-title">Юр. лицо:</div>
          <div class="document-item__props-data">{{ legalEntity }}</div>
        </div>
        <div class="document-item__props-item">
          <div class="document-item__props-title">Сотрудник:</div>
          <div class="document-item__props-data">{{ employee }}</div>
        </div>
        <div class="document-item__props-item">
          <div class="document-item__props-title">Дата загрузки:</div>
          <div class="document-item__props-data">{{ formatDate(date) }}</div>
        </div>
        <div class="document-item__props-item" v-if="seenDate">
          <div class="document-item__props-title">Дата просмотра:</div>
          <div class="document-item__props-data">{{ formatDate(seenDate) }}</div>
        </div>
      </div>
    </div>
    <VuePdfEmbed v-if="file" :source="file"  :width="documentWidth" />
  </div>
</template>

<script>
import { inject, computed, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
export default {
  components: {
    VuePdfEmbed
  },
  props: {
    name: {
      type: String,
      default: null
    },
    legalEntity: {
      type: String,
      default: null
    },
    employee: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    file: {
      default: null
    },
    status: {
      default: null
    },
    signedDate: {
      type: String,
      default: ''
    },
    seenDate: {
      type: Date,
      default: null
    },
  },
  setup(props) {
    const documentHelpers = inject('documentHelpers')
    const statusLocalize = computed(() => documentHelpers.localizeStatus(props.status))
    const documentWidth = computed(() => {
      const maxWidth = 1250
      return Math.min(maxWidth, window.innerWidth)
    })
    const tagDate = ref('')
    if (props.signedDate) {
      tagDate.value = documentHelpers.getDate(props.signedDate)
    }
    const tooltipDate = ref('')
    if (props.signedDate) {
      tooltipDate.value = documentHelpers.getDate(props.signedDate) + ' ' + documentHelpers.getTime(props.signedDate)
    }
    return {
      statusLocalize,
      tagDate,
      tooltipDate,
      documentWidth
    }
  }
}
</script>

<style lang="scss">
.document-item {
  $this: &;
  &__title {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 2px;
    font-size: 20px;
    display: flex;
    align-items: center;
    &-content {
      margin-right: 10px;
    }
  }
  &__status {
    display: inline-block;
    color: #333;
    background-color: rgba(51,51,51,0.2);
    position: relative;
    &:hover {
      #{$this} {
        &__signed-date {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    &-signed {
      color: #fff;
      background-color: $successColor;
    }
    &-completed {
      color: #fff;
      background-color: $successColor;
    }
    &-none {
      color: #fff;
      background-color: $successColor;
    }
    &-rejected {
      color: #fff;
      background-color: $rejectedColor;
    }
  }
  &__props {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px 0;
    &-item {
      display: flex;
      width: 50%;
      gap: 10px;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &__signed-date {
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: calc(100% + 4px);
    left: 0;
    background: #757575;
    padding: 3px 10px;
    font-size: 1.1em;
    border-radius: 5px;
  }
  &__title {
    flex-wrap: wrap;
  }
}
</style>
