<template>
  <v-text-field
    class="ui-field"
    :clearable="isClearable"
    :type="isPassword ?  'password' : 'text'"
    :label="label"
    :error-messages="error"
    :error="!!error"
    :model-value="model"
    @update:model-value="onChange"
  />
</template>

<script>
import { Mask } from 'maska';
import { ref } from 'vue'
export default {
  props: {
    isClearable: {
      type: Boolean,
      default: false
    },
    isPassword: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      default: null
    },
    mask: {
      type: String,
      default: ''
    }
  },
  emits: ['update:model-value'],
  setup(props, {emit}) {
    const model = ref('')
    return {
      model,
      onChange (val) {
        if (props.mask) {
          emit('update:model-value', new Mask({ mask: props.mask }).masked(val))
          return
        }
        emit('update:model-value', val)
      }
    }
  }
}
</script>

<style lang="scss">
.ui-field {
  margin-bottom: 5px;
}
</style>
