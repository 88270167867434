export default {
    $setMeta({ title, description, image, url }) {
      const setContent = (selector, value) => {
        selector?.setAttribute("content", value);
      };
  
      if (title) {
        document.title = title;
        setContent(document.querySelector("meta[property='og:title']"), title);
      }
      if (description) {
        setContent(
          document.querySelector("meta[name='description']"),
          description
        );
        setContent(
          document.querySelector("meta[property='og:description']"),
          description
        );
      }
      if (image) {
        setContent(document.querySelector("meta[property='og:image']"), image);
      }
      if (url) {
        setContent(document.querySelector("meta[property='og:url']"), url);
      }
    },
    $getMortgage(price, pay, percent, years) {
      const i = parseFloat(percent / 100 / 12);
      const n = parseFloat(years * 12);
      const r =
        (price - pay) * ((i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
  
      return r.toFixed(2);
    },
  };
  