<template>
  <div class="page-contol">
    <div class="page-contol__left" v-if="backLink">
      <router-link
        class="page-contol__back-link"
        :to="backLink"
      >
        <v-icon icon="mdi-arrow-left" />{{ backLinkText }}
      </router-link>
    </div>
    <div class="page-contol__right" :class="{'w-100': !backLink}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backLink: {
      default: null
    },
    backLinkText: {
      default: ''
    }
  }
}
</script>

<style lang="scss">
.page-contol {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__left {
    display: flex;
    align-items: center;
  }
  &__back-link {
    color: #333;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    i {
      margin-right: 7px;
      font-size: 16px;
    }
  }
  &__right {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
