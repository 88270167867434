import { send, sendAuthorized } from './common.js'
export default {
  requestCode: function (phone) {
    return send({
      url: 'auth/user-kedo/request-code',
      method: 'POST',
      body: {
        phone
      }
    })
  },
  login: function (code, public_token) {
    return send({
      url: 'auth/user-kedo/login',
      method: 'POST',
      body: {
        code,
        public_token
      }
    })
  },
  logout: function () {
    return send({
      url: 'auth/user-kedo/logout',
      method: 'POST',
    })
  },
  sendPassportCode: function (code) {
    return sendAuthorized({
      url: 'kedo/check-passport',
      method: 'POST',
      body: {
        code
      }
    })
  },
  getUser: function () {
    return sendAuthorized({
      url: 'kedo/get-user',
      method: 'GET',
    })
  },
}
