<template>
  <div class="ui-loader">
    <div class="ui-loader__container">
      <img class="ui-loader__icon" src="@/assets/loader.png">
    </div>
  </div>
</template>

<style lang="scss">
.ui-loader {
  &__icon {
    width: 60px;
    height: 60px;
    display: block;
    animation-duration: 1.5s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: step-end;
  }
  &__container {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
  }
  @keyframes rotate {
    @for $i from 1 through 12 {
      $item: (calc(calc(100 / 12) * $i));
      $percent: '%';
      #{$item}#{$percent} {
        transform: rotate(calc($i * 30deg));
      }
    }
  }
}
</style>
