<template>
  <div class="app">
    <router-view/>
    <ModalConform :openned="opennedPopup == 'conform'" v-bind="propsPopup" />
    <ModalReject :openned="opennedPopup == 'reject'" v-bind="propsPopup" />
    <ModalInfo :openned="opennedPopup == 'info'" v-bind="propsPopup" />
  </div>
</template>

<script>
import { inject, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import ModalConform from '@/components/popups/ModalConform.vue'
import ModalReject from '@/components/popups/ModalReject.vue'
import ModalInfo from '@/components/popups/ModalInfo.vue'
export default {
  name: 'App',
  components: {
    ModalConform,
    ModalReject,
    ModalInfo,
  },
  setup () {
    const store = useStore()
    const opennedPopup = computed(() => store.getters.getOpenned)
    const propsPopup = computed(() => store.getters.getProps)
    onMounted(() => {
      store.dispatch('getUserProperties')
      store.commit('setPublicToken', window.localStorage.getItem('public_token'))
    })
    return {
      opennedPopup,
      propsPopup,
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
h1 {
  font-size: 20px;
}

a {
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}
.page-bottom {
  padding-bottom: 80px;
}

.tag-wrapper {
  padding: 1px 10px;
  border-radius: 14px;
  font-size: 10px;
}

.modal-cross-icon {
  width: 30px;
  height: 30px;
  border: 0;
  padding: 0;
  position: relative;
  transform: scale(0.8);
  &::after,
  &::before {
    content: '';
    width: 30px;
    height: 2px;
    top: 50%;
    position: absolute;
    background-color: #000;
    left: 0;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}
.v-btn-group--density-default.v-btn-group {
  max-height: 36px;
}

.vue-pdf-embed__page {
  canvas {
    width: 100%!important;
    height: auto!important;
    position: relative;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background-image: url('@/assets/loader.png');
    width: 60px;
    height: 60px;
    display: block;
    animation-duration: 1.5s;
    animation-name: rotateLoader;
    animation-iteration-count: infinite;
    animation-timing-function: step-end;
    background-size: 100%;
    z-index: 0;
  }

  @keyframes rotateLoader {
    @for $i from 1 through 12 {
      $item: (calc(calc(100 / 12) * $i));
      $percent: '%';
      #{$item}#{$percent} {
        transform: rotate(calc($i * 30deg));
      }
    }
  }
}
</style>
