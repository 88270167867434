<template>
  <div class="base-layout">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="scss">
.default-layout {
  min-height: 100vh;
}
</style>
