import api from '@/api'

export default {
  state: () => ({
    nqes: null,
    firstName: null,
    lastName: null,
    patronymic: null,
    employees_id: [],
  }),
  getters: {
    getNQES (state) {
      return state.nqes
    },
    getFirstName (state) {
      return state.firstName
    },
    getLastName (state) {
      return state.lastName
    },
    getPatronymic (state) {
      return state.patronymic
    },
    getEmployeesId (state) {
      return state.employees_id
    },
  },
  mutations: {
    setNQES (state, payload) {
      state.nqes = payload
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
    setPatronymic (state, payload) {
      state.patronymic = payload
    },
    setEmployeesId (state, payload) {
      state.employees_id = payload
    },
  },
  actions: {
    getUserProperties ({commit}) {
      const user = api.auth.getUser()
      return user.then(data => {
        if (data.success) {
          commit('setFirstName', data.data.firstName)
          commit('setLastName', data.data.lastName)
          commit('setPatronymic', data.data.patronymic)
          commit('setNQES', data.data.NQES)
          commit('setEmployeesId', data.data.employees_id)
        }
      })
    }
  }
}
