<template>
  <v-btn :class="['ui-button']" :variant="variant" color="primary" @click="click">
    <slot />
    <template v-if="iconBefore" v-slot:prepend>
      <v-icon :icon="iconBefore" />
    </template>
    <template v-if="iconAfter" v-slot:append>
      <v-icon :icon="iconAfter" />
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'elevated'
    },
    iconBefore: {
      type: String,
      default: null
    },
    iconAfter: {
      type: String,
      default: null
    },
  },
  emits: ['click'],
  setup(_, {emit}) {
    return {
      click: () => {
        emit('click')
      }
    }
  }
}
</script>

<style lang="scss">
  .ui-button {
    &.v-btn{
      justify-content: space-between;
    }
  }
</style>
