<template>
  <div class="agreement">
    <div class="agreement__header">
      <router-link :to="{name: 'Login'}">
        <UiButton variant="plain" icon-before="mdi-arrow-left">Назад</UiButton>
      </router-link>
      <h1>{{ title }}</h1>
    </div>
    <div class="agreement__content">
      <slot />
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue'
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    UiButton
  }
}
</script>

<style lang="scss">
.agreement {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 40px 20px;
  @media screen and (min-width: 1024px) {
    padding: 80px 40px;
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }
  &__content {
    padding-top: 40px;
    text-align: justify;
  }
}
</style>
