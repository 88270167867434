<template>
  <div class="document-icon">
    <img :src="iconData.url">
    <div class="document-icon__tip">{{ iconData.title }}</div>
  </div>
</template>

<script>
import { computed, require } from 'vue'
export default {
  props: {
    icon: {
      default: null
    }
  },
  setup(props) {
    const iconData = computed(() => {
      switch (props.icon) {
        case ('deleted'):
          return {
            title: 'Удален', url: require('@/assets/fail.svg')
          }
        case ('draft'):
          return {
            title: 'Черновик', url: require('@/assets/ondraw.svg')
          }
        case ('rejected'):
          return {
            title: 'Отклонен', url: require('@/assets/fail.svg')
          }
        case ('signed'):
          return {
            title: 'Успешно подписан', url: require('@/assets/success.svg')
          }
        case ('in_process'):
          return {
            title: 'На подписании', url: require('@/assets/inprocess.svg')
          }
        case ('none'):
          return {
            title: 'На ознакомлении', url: require('@/assets/success.svg')
          }
        case ('completed'):
          return {
            title: 'Завершен', url: require('@/assets/success.svg')
          }
        default:
          return {
            title: 'На ознакомлении', url: require('@/assets/success.svg')
          }
      }
    })
    return {
      iconData
    }
  }
}
</script>

<style lang="scss">
.document-icon {
  height: 24px;
  width: 24px;
  position: relative;
  &:hover {
    .document-icon__tip {
      display: block;
    }
  }
  img {
    height: 100%;
  }
  &__tip {
    position: absolute;
    display: none;
    white-space: nowrap;
    font-size: 10px;
    line-height: 1;
    color: #fff;
    background: #000;
    border-radius: 10px;
    padding: 2px 5px;
    bottom: calc(100% + 4px);
    left: 12px;
    z-index: 4;
    transform: translateX(-50%);
  }
}
</style>
