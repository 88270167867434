export default {
  state: () => ({
    public_token: null,
    debug_code: null,
  }),
  getters: {
    getPublicToken (state) {
      return state.public_token
    },
    getDebugCode (state) {
      return state.debug_code
    },
  },
  mutations: {
    setPublicToken (state, payload) {
      state.public_token = payload
    },
    setDebugCode (state, payload) {
      state.debug_code = payload
    },
  },
  actions: {
    updateToken ({ commit }, data) {
      commit('setPublicToken', data)
      window.localStorage.setItem('public_token', data)
    }
  }
}
