<template>
  <ModalWrapper :title="title" :callbackCancel="callbackCancel">
    <div class="modal-reject">
      <div class="modal-reject__form">
        <UiField
          label="Комментарий"
          v-model="formCode"
        />
      </div>
      <div class="modal-buttons">
        <UiButton @click="() => {callbackCancel(); close()}">Отмена</UiButton>
        <UiButton
          @click="() => {callbackConform(formCode); close()}"
          :disabled="formCode.length == 0"
        >Подтвердить</UiButton>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import ModalWrapper from '@/components/popups/ModalWrapper.vue'
import UiButton from '@/components/ui/UiButton.vue'
import UiField from '@/components/ui/UiField.vue'
export default {
  components: {
    ModalWrapper,
    UiButton,
    UiField
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    callbackConform: {
      type: Function,
      default: null
    },
    callbackCancel: {
      type: Function,
      default: null
    }
  },
  setup () {
    const formCode = ref('')
    const store = useStore()
    return {
      formCode,
      close () {
        store.dispatch('closePopup')
      }
    }
  }
}
</script>

<style lang="scss">
.modal-reject {
  &__form {
    margin-bottom: 20px;
  }
}
</style>
