<template>
  <thead class="document-filter">
    <tr>
      <th class="document-filter__status">
        <div class="document-filter__button">
          <UiSelect
            label="Статус"
            v-model="filterStatuses"
            :items="docStatuses"
            autocomplete="off"
            multiple
            direction="horizontal"
            variant="solo"
            flat
          /></div>
      </th>
      <th class="text-left">
        <div class="document-filter__button">№</div>
      </th>
      <th class="document-filter__types">
        <div class="document-filter__button">
          Тип
          <!-- <UiSelect
            label="Тип"
            v-model="filterTypes"
            :items="docTypes"
            multiple
            autocomplete="off"
            hide-details
          /> -->
        </div>
      </th>
      <th class="text-left">
        <button
          @click="sortByDocumentDate"
          class="document-filter__button document-filter__button-sort"
          :class="{'document-filter__button-sort--active': filterSort == 'DOCUMENT_DATE', 'document-filter__button-sort--asc': filterSortDir == 'ASC'}"
          >
            Дата документа
            <v-icon class="document-filter__button-sort-icon" icon="mdi-arrow-down"></v-icon>
        </button>
      </th>
      <th class="text-left">
        <div class="document-filter__button">
          Юр. лицо
          <!-- <UiSelect
            label="Юр. лицо"
            v-model="filterLegals"
            :items="docLegal"
            multiple
            autocomplete="off"
          /> -->
        </div>
      </th>
      <th class="text-left">
        <button
          @click="sortByCreatedDate"
          class="document-filter__button document-filter__button-sort"
          :class="{'document-filter__button-sort--active': filterSort == 'CREATED_DATE', 'document-filter__button-sort--asc': filterSortDir == 'ASC'}"
        >
          Дата загрузки
          <v-icon class="document-filter__button-sort-icon" icon="mdi-arrow-down"></v-icon>
        </button>
      </th>
    </tr>
  </thead>
</template>

<script>
import { inject, onMounted, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import UiSelect from '@/components/ui/UiSelect.vue'
export default {
  components: {
    UiSelect
  },
  setup () {
    const api = inject('apiMethods')
    const docTypes = ref([])
    const docLegal = ref([])
    const store = useStore()
    const docStatuses = ref([
      {
        title: 'В процессе',
        value: 'IN_PROCESS'
      },
      {
        title: 'Завершен',
        value: 'COMPLETED'
      },
      {
        title: 'Отклонен',
        value: 'REJECTED'
      },
    ])
    const filter = computed(() => store.getters.getDocumentFilter)
    const filterTypes = ref([])
    const filterStatuses = ref(null)
    const filterSort = ref('CREATED_DATE')
    const filterSortDir = ref('DESC')
    const filterLegals = ref([])
    onMounted(() => {
      store.commit('setDocumentFilter', {
        page: 1,
        limit: 10,
        offset: 0,
        statuses: null,
        docTypes: [],
        sortField: filterSort.value,
        sortDir: filterSortDir.value,
        legals: filterLegals.value
      })
      // const props = []
      // props.push(api.documents.getDocumentLegalEntities())
      // props.push(api.documents.getDocumentTypes())
      // Promise.all(props).then(data => {
      //   // const lelist = []
      //   // if (data[0].data.result) {
      //   //   data[0].data.legalEntities.forEach(item => {
      //   //     const listitem = {
      //   //       title: item.name,
      //   //       value: item.id
      //   //     }
      //   //     lelist.push(listitem)
      //   //   })
      //   // }
      //   // docLegal.value = lelist
      //   const tlist = []
      //   if (data[0].data.result) {
      //     data[0].data.documentTypes.forEach(item => {
      //       const listitem = {
      //         title: item.name,
      //         value: item.id
      //       }
      //       tlist.push(listitem)
      //     })
      //   }
      //   docTypes.value = tlist
      // })
    })
    watch(filterSort, () => {
      store.commit('setDocumentFilter', {sortField: filterSort.value, sortDir: filterSortDir.value, page: 1})
    })
    watch(filterSortDir, () => {
      store.commit('setDocumentFilter', {sortDir: filterSortDir.value, page: 1})
    })
    watch(filterStatuses, (newVal) => {
      if (newVal.length == 0) {
        store.commit('setDocumentFilter', {statuses: [], page: 1})
      } else {
        const data = [].concat(newVal)
        if (data.indexOf('IN_PROCESS') >= 0) {
          data.push('AWAITING_MY_SIGNING')
        }

        store.commit('setDocumentFilter', {statuses: data, page: 1})
      }
    })
    watch(filterTypes, (newVal) => {
      store.commit('setDocumentFilter', {docTypes: newVal, page: 1})
    })
    watch(filterLegals, (newVal) => {
      store.commit('setDocumentFilter', {legals: newVal, page: 1})
    })
    return {
      docTypes,
      filterTypes,
      filter,
      docStatuses,
      filterStatuses,
      filterSort,
      filterSortDir,
      docLegal,
      filterLegals,
      sortByCreatedDate () {
        if (filterSort.value != 'CREATED_DATE') {
          filterSort.value = 'CREATED_DATE'
          filterSortDir.value = 'DESC'
        } else {
          if (filterSortDir.value == 'DESC') {
            filterSortDir.value = 'ASC'
          } else {
            filterSortDir.value = 'DESC'
          }
        }
      },
      sortByDocumentDate () {
        if (filterSort.value != 'DOCUMENT_DATE') {
          filterSort.value = 'DOCUMENT_DATE'
          filterSortDir.value = 'DESC'
        } else {
          if (filterSortDir.value == 'DESC') {
            filterSortDir.value = 'ASC'
          } else {
            filterSortDir.value = 'DESC'
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.document-filter {
  position: relative;
  &__button {
    .v-input__details {
      display: none;
    }
    .ui-select {
      margin-bottom: 0;
    }
    .v-field__overlay {
      display: none;
    }
    .v-field {
      background: transparent;
    }
  }
  &__button-sort {
    color: $buttonTheme;
    &-icon {
      display: none!important;
    }
    &--active {
      .document-filter__button-sort-icon {
        display: inline!important;
      }
    }
    &--asc {
      .document-filter__button-sort-icon::before {
        transform: rotate(180deg);
      }
    }
  }
  &__status {
    width: 150px;
  }
  &__types {
    width: 300px;
  }
}
</style>
