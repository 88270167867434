<template>
  <div class="document-page page-bottom">
    <ContainerWrapper>
      <PageControl :backLink="{name: 'Documents'}" backLinkText="Реестр документов">
        <UiButton v-if="loadedData && !error" @click="download" variant="text">Скачать пакет документов</UiButton>
        <div class="document-page__buttons">
          <UiButton :disabled="!loadedData || waitConform" v-if="!error && isAvailable && !(signed || rejected) || isDebug" @click="cancelDocument">Отклонить</UiButton>
          <UiButton :disabled="!loadedData || waitConform" v-if="!error && isAvailable && !(signed || rejected) || isDebug" @click="signDocument">Подписать УНЭП</UiButton>
        </div>
      </PageControl>

      <div class="document-page__content" v-if="loadedData">
        <DocumentItem
          v-if="!error && documentProperties"
          :name="getName()"
          :legalEntity="getLegalEntity()"
          :employee="getEmployee()"
          :date="getDate()"
          :status="getStatus()"
          :signedDate="signedDate"
          :seenDate="seenDate"
          :file="documentFile"
        />
        <DocumentError v-else />
      </div>
      <div class="document-page__loader" v-if="!loadedData || !documentFile">
        <UiLoader/>
      </div>
    </ContainerWrapper>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject, watch } from 'vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import ContainerWrapper from '@/components/ContainerWrapper.vue'
import DocumentItem from '@/components/DocumentItem.vue'
import DocumentError from '@/components/DocumentError.vue'
import PageControl from '@/components/PageControl.vue'
import UiButton from '@/components/ui/UiButton.vue'
import UiLoader from '@/components/ui/UiLoader.vue'
export default {
  components: {
    ContainerWrapper,
    DocumentItem,
    DocumentError,
    PageControl,
    UiButton,
    UiLoader
  },
  setup () {
    const api = inject('apiMethods')
    const documentHelpers = inject('documentHelpers')
    const route = useRoute()
    const store = useStore()
    const documentProperties = computed(() => store.getters.getDocument)
    const documentFile = computed(() => store.getters.getDocumentFile)
    const id = ref(null)
    const signed = ref(true)
    const rejected = ref(true)
    const isLoad = ref(false)
    const loadedData = ref(false)
    const waitConform = ref(false)
    const signingRequestId = ref(null)
    const error = ref(false)
    const isAvailable = ref(false)
    const timer = ref(null)
    const isDebug = ref(false)
    const signedDate = ref('')
    const seenDate = ref(null)
    function cancel() {
      isLoad.value = false
      waitConform.value = false
    }

    function getStatus () {
      return documentHelpers.getStatus(documentProperties.value)
    }

    function fetchDocument() {
      isLoad.value = true
      return store.dispatch('fetchDocument', id.value).then(() => {
        if (documentProperties.value) {
          signed.value = documentProperties.value.signed
          rejected.value = documentProperties.value.rejected
          isAvailable.value = getStatus() == 'in_process'
          const employee = documentHelpers.getDocumentEmployee(documentProperties.value)
          if (employee) {
            if (employee.signedDate) {
              signedDate.value = new Date(employee.signedDate)
            }
            seenDate.value = employee.seenDate ? new Date(employee.seenDate) : null
          }
        } else {
          error.value = true
        }
      }).finally(() => {
        isLoad.value = false
        loadedData.value = true
        if (!stopInterval()) {
          setTimeout(() => {
            return fetchDocument()
          }, 5000)
        }
      })
    }
    function conformDocument (code) {
      api.documents.conformDocument(id.value, signingRequestId.value, code).then(() => {
        setTimeout(() => {
          isLoad.value = true
          fetchDocument().then(() => {
            store.dispatch('fetchPdf', id.value)
          })
        }, 2000)
        api.documents.updateStatusTask(id.value, 1, '')
      })
    }
    function rejectDocument (comment) {
      api.documents.rejectDocument(id.value, comment).then(() => {
        setTimeout(() => {
          isLoad.value = true
          fetchDocument().then(() => {
            store.dispatch('fetchPdf', id.value)
          })
        }, 2000)
        api.documents.updateStatusTask(id.value, 0, comment)
      })
    }

    function stopInterval() {
      const status = getStatus()
      switch (status) {
        case ('signed'):
          return true
        case ('rejected'):
          return true
        case ('none'):
          return true
        case ('completed'):
          return true
        default:
          return false
      }
    }

    onMounted(() => {
      const params = new URL(document.location).searchParams
      if (params.get('debug')) {
        isDebug.value = true
      }
      id.value = route.params.id
      loadedData.value = false
      store.dispatch('resetPdf', id.value)
      store.dispatch('getUserProperties').then(() => {
        fetchDocument().then(() => {
          store.dispatch('fetchPdf', id.value)
        })
      })
    })

    onBeforeRouteLeave(() => {
      clearInterval(timer.value)
    })
    return {
      documentProperties,
      documentFile,
      id,
      signed,
      rejected,
      isLoad,
      waitConform,
      signingRequestId,
      error,
      timer,
      fetchDocument,
      loadedData,
      cancel,
      isDebug,
      isAvailable,
      signedDate,
      seenDate,
      getName() {
        return documentProperties.value.type.name + (documentProperties.value.number ? ' №-' + documentProperties.value.number : '')
      },
      getLegalEntity() {
        if (!documentProperties.value.legalEntity) {
          return ''
        }
        return documentProperties.value.legalEntity.name
      },
      getEmployee() {
        return store.getters.getLastName + ' ' + store.getters.getFirstName
      },
      getDate() {
        return documentProperties.value.createdDate
      },
      getStatus,
      download () {
        api.documents.downloadArchive(id.value)
      },
      conformDocument,
      rejectDocument,
      signDocument () {
        api.documents.signDocument(id.value).then((data) => {
          signingRequestId.value = data.data.signingRequestId
          store.dispatch('openPopup',
            {
              name: 'conform',
              props: {
                withCode: true,
                title: 'Подтверждение подписи',
                callbackConform: conformDocument,
                callbackCancel: cancel
              }
            }
          )
          waitConform.value = true
        })
      },
      cancelDocument () {
        waitConform.value = true
        store.dispatch('openPopup',
          {
            name: 'reject',
            props: {
              title: 'Укажите причину отклонения',
              callbackConform: rejectDocument,
              callbackCancel: cancel
            }
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
.document-page {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }
  &__buttons {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
