<template>
  <v-select
    class="ui-select"
    :clearable="isClearable"
    :label="label"
    :error-messages="error"
    :error="!!error"
    :model-value="model"
    :items="items"
    :item-props="itemProps"
    :multiple="multiple"
    @update:model-value="onChange"
  >
    <template v-slot:selection="{ item, index }">
      <div class="ui-select__selected" v-if="index < 1">
        {{ item.title }}
        <span
        v-if="multiple && model.length > 1"
        class="ui-select__selected-addon text-grey text-caption align-self-center"
      >
        (+{{ (model.length - 1) }})
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { Mask } from 'maska';
import { ref } from 'vue'
export default {
  props: {
    isClearable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      default: null
    },
    itemProps: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  setup(props, {emit}) {
    const model = ref()
    return {
      model,
      onChange (val) {
        model.value = val
        emit('update:model-value', val)
      }
    }
  }
}
</script>

<style lang="scss">
.ui-select {
  margin-bottom: 5px;
  &__selected {
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &-addon {
      position: absolute;
      left: 80%;
    }
  }
}
</style>
