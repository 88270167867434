<template>
  <div class="login-page">
    <div class="login-page__form">
      <TheLogo class="login-page__logo" />
      <div class="login-page__title">
        <h1>Войти в КЭДО ГК «ОСНОВА»</h1>
      </div>
      <LoginForm
        v-if="loginStage == 0"
        ref="loginForm"
        :disabled="loginFormDisabled"
        @submit-phone="loginFormSubmitPhone"
        @submit-code="loginFormSubmitCode"
        @resend="loginFormSubmitPhone"
        :errors="loginFormErrors"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue'
import LoginForm from '@/components/forms/LoginForm.vue'
import TheLogo from '@/components/TheLogo.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    TheLogo
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const api = inject('apiMethods')
    const loginForm = ref()
    const loginFormErrors = ref({})
    const loginFormDisabled = ref(false)
    const loginFormData = ref()
    const loginStage = ref(0)
    onMounted(() => {
      api.auth.getUser().then((data) => {
        if (window.localStorage.getItem('public_token') !== '') {
          if (data.success && data.data.NQES == 'ACTIVE') {
            api.nqes.getChangeChannelTask().then(data => {
              if (data.data && data.data.state == 'USER_NOTIFIED_ABOUT_CHANNEL_CHANGE_REQUEST') {
                router.push({name: 'NQES', query: {'toggle': '1'}})
                return
              }
              return router.push({name: 'Documents'})
            })
          } else {
            if (data.success && (data.data.NQES == 'USER_NOTIFIED_ABOUT_ISSUE_REQUEST' || data.data.NQES == 'USER_NOTIFIED_ABOUT_CERTIFICATE')) {
              router.push({name: 'NQES'})
            }
          }
        }
      })
    })
    return {
      loginForm,
      loginFormErrors,
      loginFormData,
      loginStage,
      loginFormDisabled,
      loginFormSubmitPhone: (data) => {
        if (!data) {
          loginFormErrors.value = {
            phone: 'Введите номер телефона'
          }
          return
        }
        loginFormData.value = data
        loginFormDisabled.value = true
        const code = api.auth.requestCode(data)
        code.then(data => {
          if (!data.success) {
            loginFormErrors.value = {
              phone: 'Номер не найден'
            }
            return
          } else {
            loginForm.value.showCodeField()
            loginForm.value.setTimeout()
            store.dispatch('updateToken', data.data.public_token)
          }
        })
        .finally(() => {
          loginFormDisabled.value = false
        })
      },
      loginFormSubmitCode: (data) => {
        if (!data) {
          loginFormErrors.value = {
            code: 'Введите код'
          }
          return
        }
        loginFormDisabled.value = true
        const code = api.auth.login(data, store.getters.getPublicToken)
        code.then((data) => {
          if (!data.success) {
            loginFormErrors.value = {
              code: 'Неверный код'
            }
            return
          } else {
            store.dispatch('updateToken', data.data.session.access_token)
            store.dispatch('getUserProperties')
            const nqes = data.data.NQES
            api.nqes.getChangeChannelTask().then(data => {
              if (data.data && data.data.state == 'USER_NOTIFIED_ABOUT_CHANNEL_CHANGE_REQUEST') {
                router.push({name: 'NQES', query: {'toggle': '1'}})
                return
              }
              if (nqes == 'ACTIVE') {
                router.push({name: 'Documents'})
                return
              }
              if (nqes == 'ANNULLED') {
                loginFormErrors.value = {
                  phone: 'Нет действующего сертификата',
                  code: ''
                }
                loginForm.value.reset()
                return
              }
              if (nqes == 'NONE') {
                loginFormErrors.value = {
                  phone: 'Сертификат не действителен, и пользователь не приглашен',
                  code: ''
                }
                loginForm.value.reset()
                return
              }
              if (nqes == 'NQES_ISSUE_REQUEST_FAILED' || nqes == 'ISSUING_REJECTED') {
                loginFormErrors.value = {
                  phone: 'Заявка на выпуск УНЭП завершилась с ошибкой',
                  code: ''
                }
                loginForm.value.reset()
                return
              }
              
              if (
                nqes == 'USER_NOTIFIED_ABOUT_ISSUE_REQUEST'
                || nqes == 'USER_NOTIFIED_ABOUT_CERTIFICATE'
                || nqes == 'NQES_ISSUE_REQUEST_CREATED'
              ) {
                router.push({name: 'NQES'})
                return
              }
              loginFormErrors.value = {
                phone: 'Сертификат не действителен или отсутствует заявление на выпуск',
                code: ''
              }
              loginForm.value.reset()
              return
            })
          }
        })
        .finally(() => {
          loginFormDisabled.value = false
        })
      },
    }
  }
}
</script>

<style lang="scss">
.login-page {
  display: flex;
  padding: 80px 30px 30px 30px;

  &__title {
    margin-bottom: 80px;
  }

  &__form {
    width: 300px;
  }
  &__content {
    width: calc(100% - 300px);
  }
  &__logo {
    margin-bottom: 10px;
  }
}
</style>
