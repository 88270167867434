import { sendAuthorized } from './common.js'
export default {
  getTaskFile: function () {
    return sendAuthorized({
      url: 'kedo/nqes/task-file',
      method: 'GET',
    })
  },
  signTask: function (debug = false) {
    if (debug) {
      return new Promise((resolve) => {
        resolve('{"success": true}')
      })
    }
    return sendAuthorized({
      url: 'kedo/nqes/task-sign',
      method: 'POST',
    })
  },
  conformTask: function (code, debug = false) {
    if (debug) {
      return new Promise((resolve) => {
        resolve('{"success": true}')
      })
    }
    return sendAuthorized({
      url: 'kedo/nqes/task-conform',
      method: 'POST',
      body: {
        code: code
      }
    })
  },
  rejectTask: function (comment, debug = false) {
    if (debug) {
      return new Promise((resolve) => {
        resolve('{"success": true}')
      })
    }
    return sendAuthorized({
      url: 'kedo/nqes/task-reject',
      method: 'POST',
      body: {
        comment: comment
      }
    })
  },
  getCertFile: function () {
    return sendAuthorized({
      url: 'kedo/nqes/cert-file',
      method: 'GET',
    })
  },
  conformCert: function (debug = false) {
    if (debug) {
      return new Promise((resolve) => {
        resolve('{"success": true}')
      })
    }
    return sendAuthorized({
      url: 'kedo/nqes/cert-conform',
      method: 'POST',
    })
  },
  download: function () {
    return sendAuthorized({
      url: 'kedo/nqes/download-cert-file',
      method: 'GET',
      isBlob: true
    }).then(data => {
      const file = URL.createObjectURL(data);
      const link = document.createElement('a')
      link.href = file
      link.setAttribute('download', '')
      link.click()
    })
  },
  getChangeChannelTask: function () {
    return sendAuthorized({
      url: 'kedo/nqes/change-channel-task',
      method: 'GET',
    })
  },
  getChangeChannelTaskFile: function (getPrintFile = false) {
    let url = 'kedo/nqes/change-channel-taskfile'
    if (getPrintFile) {
      url = 'kedo/nqes/change-channel-taskprintfile'
    }
    return sendAuthorized({
      url: url,
      method: 'GET',
    })
  },
  changeChannelCodeRequest: function () {
    return sendAuthorized({
      url: 'kedo/nqes/change-channel-request',
      method: 'POST',
    })
  },
  changeChannelConfirm: function (code) {
    return sendAuthorized({
      url: 'kedo/nqes/change-channel-confirm',
      method: 'POST',
      body: {
        code: code
      }
    })
  },
  changeChannelReject: function (comment) {
    return sendAuthorized({
      url: 'kedo/nqes/change-channel-reject',
      method: 'POST',
      body: {
        comment: comment
      }
    })
  },
}
