<template>
  <v-checkbox class="ui-checkbox">
    <template v-slot:label><slot /></template>
  </v-checkbox>
</template>

<style lang="scss">
.ui-checkbox {
  .v-selection-control {
    display: block;
  }
  .v-selection-control__wrapper {
    display: inline-block;
  }
  .v-selection-control .v-label {
    display: inline;
    font-size: 14px;
  }
}
</style>
