import auth from './auth.js'
import debug from './debug.js'
import documents from './documents.js'
import nqes from './nqes.js'
export default {
  auth,
  debug,
  documents,
  nqes
}
