<template>
  <v-form>
    <slot />
  </v-form>
</template>

<script>
import { ref } from 'vue'
export default {
  setup(_, {emit}) {
    const errors = ref(null)
    const setErrors = function (err) {
      errors.value = err
    }
    const updateError = function (name, err) {
      if (errors.value) {
        errors.value[name] = err
      }
    }
    const getErrors = function () {
      if (!errors.value) {
        return {}
      }
      return errors.value
    }
    return {
      errors,
      setErrors,
      updateError,
      getErrors,
    }
  }
}
</script>
