import LoginPage from '@/views/LoginPage'
import DocumentsPage from '@/views/DocumentsPage'
import DocumentPage from '@/views/DocumentPage'
import DefaultLayout from '@/layouts/DefaultLayout'
import BaseLayout from '@/layouts/BaseLayout'
import UserAgreement from '@/views/UserAgreement'
import ConfPolicy from '@/views/ConfPolicy'
import DebugSafari from '@/views/DebugSafari'
import PersonalDataPolicy from '@/views/PersonalDataPolicy'
import NQES from '@/views/NQES'

const routes = [
  {
    path: '',
    redirect: { name: 'Login' },
    component: BaseLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
          guest: true
        }
      },
      {
        path: '/useragreement',
        name: 'UserAgreement',
        component: UserAgreement,
        meta: {
          guest: true
        }
      },
      {
        path: '/confpolicy',
        name: 'ConfPolicy',
        component: ConfPolicy,
        meta: {
          guest: true
        }
      },
      {
        path: '/personaldatapolicy',
        name: 'PersonalDataPolicy',
        component: PersonalDataPolicy,
        meta: {
          guest: true
        }
      },
      {
        path: '/debugSafari',
        name: 'debugSafari',
        component: DefaultLayout,
        meta: {
          guest: true,
        },
        children: [
          {
            path: '/debugSafari/document/:id',
            name: 'DocumentSafari',
            component: DebugSafari,
          },
        ]
      },
    ]
  },
  {
    path: '/',
    redirect: { name: 'Login' },
    component: DefaultLayout,
    children: [
      {
        path: '/documents',
        name: 'Documents',
        component: DocumentsPage,
      },
      {
        path: '/documents/:id',
        name: 'DocumentInner',
        component: DocumentPage
      },
      {
        path: '/nqes',
        name: 'NQES',
        component: NQES
      }
    ]
  },
]

export default routes
