<template>
  <AggreementContent title="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ">
    <p>Перед началом использования Сервиса, пожалуйста, ознакомьтесь с условиями настоящей политики конфиденциальности (далее - Политика конфиденциальности) и Пользовательского соглашения. Факт использования Сервиса любым способом (как намеренным, так и непреднамеренным) признается акцептом, то есть полным и безоговорочным согласием любого пользователя Сервиса, использовать Сервис на изложенных ниже условиях. Использование Сервиса допустимо исключительно на условиях Пользовательского соглашения и Политики конфиденциальности. Если Пользователь не принимает условия указанных документов в полном объеме, он не вправе использовать Сервис в каких-либо целях.</p>

<br><p><b>1. Термины и определения</b></p>
<p>В настоящей Политике конфиденциальности используются следующие термины:</p>
<p>1.1. Персональные данные - любая информация, относящаяся к прямо или косвенно определенному, или определяемому Пользователю Сервиса (физическому лицу, субъекту персональных данных).</p>
<p>1.2. Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
<p>1.3. Оператор - АО «ГК «ОСНОВА» (ИНН: 9715264590; ОГРН: 1167746614530 (далее - Оператор), зарегистрированному по адресу: 142702, Московская обл., Ленинский р-н, Видное г., рабочий поселок Бутово, территория жилой комплекс Бутово-Парк, дом № 18, корпус 1, пом. 59, организующий и (или) осуществляющий обработку персональных данных, а также определяющий цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
<p>1.4. Сервис - программное обеспечение, функционирующее на ресурсах Оператора (<a href="https://kedo.gk-osnova.ru/" target="_blank">https://kedo.gk-osnova.ru/</a>, <a href="https://start.gk-osnova.ru/" target="_blank">https://start.gk-osnova.ru/</a>), для ведения кадрового электронного документооборота. Сервис позволяет осуществлять документооборот в электронном виде, создавать УНЭП, подписывать Пользователями Сервиса предложенной документации Клиента/Оператора и использовать иной функционал, доступный в Сервисе. </p>
<p>1.5. Клиент - индивидуальный предприниматель или юридическое лицо, имеющее трудовые или гражданско-правовые правоотношения с Пользователем, и предавшее Оператору поручение на обработку персональных данных Пользователей.</p>
<p>1.6. Пользователь Сервиса - любое лицо, использующее Сервис и имеющее с Клиентом/Оператором гражданско-правовые или трудовые правоотношения.</p>
<p>1.7. Электронная подпись - информация в электронной форме, которая присоединена к другой информации в электронной форме (подписываемой информации) или иным образом связана с такой информацией и которая используется для определения лица, подписывающего информацию, а также подтверждает волеизъявление лица/лиц на подписание документа.</p>
<p>1.8. Усиленная неквалифицированная электронная подпись (УНЭП) - электронная подпись в виде криптографического шифра, записанного в защищенном облачном хранилище. УНЭП идентифицирует личность владельца, подтверждает факт формирования подписи определенным лицом и неизменность документа с момента подписания. Пользователь Сервиса признает, что электронные документы, подписанные в Сервисе посредством УНЭП, признаются равнозначными документам на бумажном носителе, подписанным собственноручно. В том числе данные условия распространяются на электронные документы, подписываемые в рамках гражданско-правовых правоотношений между Климентом/Оператором и Пользователем, выступающим в качестве индивидуального предпринимателя или самозанятого.</p>
<p>1.9. Простая электронная подпись (ПЭП) - электронная подпись, которая посредством использования кодов, паролей иных средств подтверждает факт формирования электронной подписи определенным лицом. По смыслу настоящего Соглашения под ПЭП понимается логин и пароль Пользователя при входе в Сервис, а также одноразовый пароль, передаваемый Пользователю посредством SMS на его номер мобильного телефона.</p>

<br><p><b>2.	Общие положения</b></p>
<p>2.1. Настоящая Политика конфиденциальности и определяет порядок обработки и защиты информации Пользователей Сервиса.</p>
<p>2.2. Целью настоящей Политики конфиденциальности является обеспечение надлежащей защиты информации о Пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.</p>
<p>2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются настоящей Политикой конфиденциальности, иными официальными документами Оператора и действующим законодательством Российской Федерации.</p>
<p>2.4. Оператор, действуя разумно и добросовестно, считает, что при использовании Сервиса Пользователь:</p>
<p>2.4.1. Обладает всеми необходимыми правами, позволяющими ему осуществлять вход в Сервис под своей учетной записью;</p>
<p>2.4.2. Обладает всеми необходимыми правами, позволяющими ему использовать Сервис по прямому назначению;</p>
<p>2.4.3. Указывает достоверную информацию о себе в объемах, необходимых для пользования Сервисом;</p>
<p>2.4.4. Ознакомлен с настоящей Политикой конфиденциальности, выражает свое согласие с ее положениями и принимает на себя указанные в ней права и обязанности.</p>
<p>2.5. Оператор не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Оператором обязательств перед Пользователем.</p>

<br><p><b>3.	Использование Сервиса</b></p>
<p>3.1. При использовании Сервиса Пользователями, Оператору могут быть доступны персональные данные Пользователей для целей надлежащей работоспособности Сервиса и обеспечения реализации его функционала для Клиента и Пользователя.</p>
<p>3.2. В Сервисе Пользователь предоставляет свои персональные данные Оператору для обеспечения трудовых или гражданско-правовых отношений. Обработка персональных данных Пользователя регулируется документацией и правилами Оператора. Пользователю надлежит внимательно изучить данные правила и документацию Оператора. Оператор не несет ответственности за предоставление Пользователем своих персональных данных Клиенту и вытекающие из этого последствия.</p>
<p>3.3. Пользователь также предоставляет свои персональные данные для целей использования функционала Сервиса соответствующему удостоверяющему центру, уполномоченному на создание Пользователю УНЭП. Обработка персональных данных Пользователя удостоверяющим центром регулируется документацией и правилами удостоверяющего центра. Пользователю надлежит внимательно изучить данные правила и документацию удостоверяющего центра. Оператор не несет ответственности за предоставление Пользователем своих персональных данных удостоверяющему центру и вытекающие из этого последствия.</p>
<p>3.4. Для персональных данных в Сервисе обеспечивается конфиденциальность в соответствии с применимым законодательством, локальными актами Оператора/Клиента, условиями заключенных соглашений и договоров на Сервисе, кроме случаев:</p>
<p>◦ если информация подлежит обязательному раскрытию третьим лицам, включая государственные органы, в соответствии с применимым к деятельности Оператора законодательству.</p>
<p>3.5. Оператор в Сервисе и при реализации Пользователями функционала Сервиса предпринимает необходимые и достаточные правовые, организационные и технические меры для обеспечения безопасности персональных данных для их защиты от несанкционированного (в том числе, случайного) доступа, уничтожения, изменения, блокирования доступа и других несанкционированных действий.</p>
<p>3.6. Пользователь несёт ответственность за конфиденциальность своих аутентификационных данных, используемых для входа в Сервис и других систем.</p>

<br><p><b>4.	Состав информации о Пользователях</b></p>
<p>4.1. Оператор может собирать следующие категории персональных данных о Пользователе во время использования Сервиса:</p>
<p>4.1.1. Данные для использования сервиса: фамилия, имя, отчество, телефон;</p>
<p>4.1.2. Информация, предоставленная Пользователем при направлении заявки на выпуск УНЭП в Сервисе, такая как: фамилия, имя, отчество, телефон, СНИЛС, ИНН, паспортные данные, адрес регистрации, пол;</p>
<p>4.1.3. Дата и время осуществления доступа к Сервису;</p>
<p>4.1.4. Иная информация, необходимая для обработки в соответствии с условиями, регулирующими использование Сервиса.</p>
<p>4.2. Оператор не собирает данные с целью составления «портрета» Пользователя в той степени, при которой это может существенно повлиять на права Пользователя и свободы в соответствии с применимым законодательством.</p>

<br><p><b>5.	Цели обработки персональных данных</b></p>
<p>5.1. Цель обработки персональных данных Пользователя - информирование Пользователя посредством отправки смс сообщения на номер мобильного телефона; предоставление доступа Пользователю к Сервису и его функционалу, информации и/или материалам, содержащимся в Сервисе.</p>
<p>5.2. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей в Сервисе, улучшения качества Сервиса и его содержания.</p>
<p>5.3. Оператор всегда обрабатывает персональные данные в определенных целях и только те персональные данные, которые имеют отношение к достижению таких целей. В частности, Оператор, помимо указанных выше целей, обрабатывает персональные данные Пользователей для следующих целей:</p>
<p>5.3.1. Выполнение, возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей;</p>
<p>5.3.2. Выполнение обязательств Оператора перед Пользователями в отношении использования Сервиса;</p>
<p>5.3.3. Предоставления доступа к Сервису;</p>
<p>5.3.4. Осуществления связи с Пользователем для направления уведомлений, запросов и информации, относящейся к работе Сервиса/Оператора, выполнения соглашений и обработки запросов Пользователя;</p>
<p>5.3.5. Повышения удобства использования Сервиса;</p>
<p>5.3.6. Защиты прав Пользователя и Оператора;</p>
<p>5.3.7. Для осуществления прав и законных интересов Оператора или третьих лиц, в том числе в случаях, предусмотренных федеральным законодательством Российской Федерации;</p>
<p>5.3.8. Иные законные основания, предусмотренные законодательством Российской Федерации.</p>

<br><p><b>6.	Условия обработки персональных данных</b></p>
<p>6.1. Обработка персональных данных осуществляется на основе принципов:</p>
<p>6.1.1. Законности целей и способов обработки персональных данных;</p>
<p>6.1.2. Добросовестности;</p>
<p>6.1.3. Соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Оператора;</p>
<p>6.1.4. Соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;</p>
<p>6.1.5. Недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.</p>
<p>6.2. Оператор осуществляет обработку персональных данных Пользователя в целях исполнения договора между Оператором и Пользователем (Пользовательское соглашение). В силу ст. 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие Пользователя на обработку его персональных данных не требуется.</p>

<br><p><b>7.	Хранение и использование персональных данных</b></p>
<p>7.1 Персональные данные Пользователей будут обрабатываться, и храниться в Российской Федерации. При сборе персональных данных Оператор соблюдает так называемое требование локализации баз данных, то есть делает так, чтобы запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных Пользователей осуществлялись с использованием баз данных, находящихся на территории России.</p>
<p>7.2 Персональные данные пользователей обрабатываются, как с использованием автоматизированных систем, так и без таковых.</p>
<p>7.3 Оператор принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного доступа, изменения, раскрытия или уничтожения. Оператор предоставляет доступ к персональным данным Пользователя только тем лицам, которым эта информация необходима с целью исполнения назначения Сервиса и для обеспечения функционирования Сервиса, а также иных действий, незапрещённых российским законодательством.</p>
<p>7.4 Оператор вправе использовать предоставленную Пользователем информацию, в том числе персональные данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей).</p>
<p>7.5 Раскрытие предоставленной Пользователем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях.</p>
<p>7.6 Пользователь уведомлен и согласен с тем, что в целях контроля качества Оператор вправе проводить исследования переданной и полученной Пользователем информации в обезличенном агрегированном виде без возможности отнести такую информацию к прямо или косвенно определенному, или определяемому Пользователю.</p>
<p>7.7 Оператор будет хранить персональные данные столько времени, сколько это необходимо для достижения цели, для которой она была собрана, или для соблюдения требований законодательства и нормативных актов.</p>

<br><p><b>8.	Права Пользователя</b></p>
<p>8.1. Пользователь вправе:</p>
<p>8.1.1. Отозвать согласие на обработку своих персональных данных;</p>
<p>8.1.2. Требовать уточнения, исключения или исправления, а также блокирования и уничтожения неполных, неверных, устаревших, недостоверных, незаконно полученных или не являющихся необходимыми для Оператора персональных данных;</p>
<p>8.1.3. Получать доступ к своим персональным данным и, в частности, получать от Оператора следующие сведения: категории обрабатываемых персональных данных; цели обработки персональных данных; сведения о лицах, которые имеют доступ к персональным данным или которым может быть предоставлен такой доступ (за исключением работников Оператора); перечень обрабатываемых персональных данных Пользователя и источник их получения; сроки обработки персональных данных, в том числе сроки их хранения; иные сведения в соответствии с действующим законодательством Российской Федерации;</p>
<p>8.1.4. Требовать извещения Оператором всех лиц, которым ранее были сообщены неверные или неполные персональные данные, обо всех произведенных в них исключениях, исправлениях или дополнениях;</p>
<p>8.1.5. Осуществлять иные права в сфере защиты персональных данных, предусмотренные действующим российским законодательством.</p>
<p>8.2. Для отзыва согласия на обработку персональных данных Пользователю необходимо направить соответствующий запрос на электронный адрес Оператора: <a href="mailto:dpers@gk-osnova.ru">dpers@gk-osnova.ru</a>. После отзыва согласия Оператор прекратит обработку данных, уничтожит персональные данные, а также обеспечит их уничтожение привлеченными к обработке третьими лицами (за исключением случаев, если дальнейшая обработка требуется в соответствии с применимым законодательством или может осуществляться на ином законном основании).</p>

<br><p><b>9.	Файлы cookies</b></p>
<p>9.1. С целью улучшения работы Сервиса, а также удобства его использования, Оператор может получить технические файлы cookies. Оператор понимает, что в определенной степени файлы cookies должны рассматриваться в качестве персональных данных, и работа с ними должна регулироваться соответствующим законодательством.</p>
<p>9.2. Технические файлы cookies - технические файлы cookies необходимы для правильной работы Сервиса и корректного использования различных предлагаемых на нем персональных возможностей и услуг. Например, такие cookies могут использоваться для технической поддержки работы сессии, контроля времени отклика, работы или подтверждения персональных предпочтений, использования элементов безопасности и т. д.</p>

<br><p><b>10.	Передача персональных данных</b></p>
<p>10.1. Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой конфиденциальности и/или для целей использования Сервиса.</p>
<p>10.2. В большинстве случаев Персональная информация обрабатывается автоматически без доступа к ней кого-либо из сотрудников Оператора. В случае если такой доступ понадобится, то он может быть предоставлен только тем сотрудникам Оператора, которые нуждаются в этом для выполнения своих задач. Для защиты и обеспечения конфиденциальности данных все сотрудники должны соблюдать внутренние правила и процедуры в отношении обработки Персональной информации. Они также должны следовать всем техническим и организационным мерам безопасности, действующим для защиты Персональных данных Пользователя. Оператор внедрил достаточные технические и организационные меры для защиты Персональных данных от несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки. Данные меры безопасности были реализованы с учетом современного уровня техники, стоимости их реализации, рисков, связанных с обработкой и характером Персональной информации.</p>
<p>10.3. Оператор может передавать Персональные данные Пользователя своим сотрудникам </p>
<p>(в пределах, указанных в п.10.2 настоящей Политики). Оператор также может передавать Персональные данные Пользователя своим аффилированным лицам. Пользователь предоставляет свое согласие на такое распространение своих персональных данных для исполнения целей настоящей Политики.</p>
<p>10.4. Во всех случаях Персональные данные также будут обрабатываться только для целей, изложенных в настоящей Политике.</p>
<p>10.5. Оператор также может передавать Персональные данные третьим лицам, не входящим в группу компаний оператора, для достижения целей, указанных в Политике. К таким третьим лицам могут относиться:</p>
<p>10.5.1. Третьи лица, в отношении которых произведена уступка прав или обязанностей, или новация по соответствующему соглашению;</p>
<p>10.5.2. Любые регулирующие органы, правоохранительные органы, центральные или местные исполнительные органы власти, другие официальные или государственные органы, или суды, в отношении которых Оператор обязан предоставлять информацию в соответствии с применимым законодательством по соответствующему запросу;</p>
<p>10.5.3. Третьи лица, в случае если Пользователь выразил согласие на передачу своих Персональных данных либо передача Персональной информации требуется для предоставления Пользователю соответствующего сервиса или выполнения определенного соглашения или договора, заключенного с Пользователем;</p>
<p>10.5.4. Любые третьи лица в целях обеспечения правовой защиты Оператора или третьих лиц при нарушении Пользователем Пользовательского соглашения сервисов Оператора, настоящей Политики или условий, регулирующих использование отдельных сервисов, либо в ситуации, когда существует угроза такого нарушения.</p>
<p>10.5.5. В иных случаях при наличии согласия Пользователя возможна передача персональных данных Пользователя третьим лицам-контрагентам Оператора с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации.</p>

<br><p><b>11.	Уничтожение персональных данных</b></p>
<p>11.1. Оператор обрабатывает персональные данные Пользователя не дольше, чем это требуется для достижения целей их обработки, указанных в Политике.</p>
<p>11.2. После достижения целей обработки персональных данных Оператор уничтожит персональные данные (а также обеспечит их уничтожение привлеченными к обработке третьими лицами) в течение 10 дней. Оператор также прекратит обработку персональных данных (а также обеспечит их уничтожение привлеченными к обработке третьими лицами), в случае если Пользователь отзовет свое согласие на обработку данных (в случаях, когда согласие является единственным законным основанием для обработки персональных данных).</p>
<p>11.3. Обращаем внимание на то, что Оператор может в некоторых случаях продолжить обработку данных после отзыва согласия, когда это требуется для выполнения обязанностей, возникающих в силу применимого законодательства, или, когда обработка необходима для осуществления прав и законных интересов Оператора и (или) третьих лиц.</p>

<br><p><b>12.	Прочие условия</b></p>
<p>12.1. Пользователь предоставляет Оператору согласие на обработку персональной информации Пользователя для целей предоставления функциональных возможностей Сервиса. Оператор передаёт/обрабатывает лишь ту персональную информацию Пользователя, которая необходима для указанной цели. Положения настоящего пункта в отношении обработки персональной и иной информации Пользователя распространяются на материалы и информацию, полученные или переданные Пользователем в процессе использования Сервиса и его функций.</p>
<p>12.2. Настоящая Политика конфиденциальности может быть изменена Оператором без какого-либо специального уведомления, новая редакция Политики конфиденциальности вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное </p>
<p>не предусмотрено новой редакцией Политики конфиденциальности. Действующая редакция Политики конфиденциальности находится на странице по адресу: <a href="https://kedo.gk-osnova.ru/" target="_blank">https://kedo.gk-osnova.ru/</a>, <a href="https://start.gk-osnova.ru/" target="_blank">https://start.gk-osnova.ru/</a>, если в Политике конфиденциальности не указано иное.</p>
<p>12.3. Настоящая Политика конфиденциальности регулируется и толкуется в соответствии с законодательством Российской Федерации. По всем вопросам, не урегулированным настоящей Политикой конфиденциальности, стороны руководствуются действующим законодательством Российской Федерации.</p>
<p>12.4. Если по каким-либо причинам одно или несколько условий настоящей Политики конфиденциальности будут признаны недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость остальных условий Политики конфиденциальности.</p>
<p>12.5. В случае если Оператором были внесены какие-либо изменения в настоящую Политику конфиденциальности, с которыми Пользователь не согласен, он обязан прекратить использование Сервиса.</p>
<br><p><b>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>

<p>В соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», действуя своей волей и в своем интересе, предоставляя данные о себе даю свое согласие на:</p>

<p>1. обработку моих персональных данных (фамилия, имя, отчество, номер мобильного телефона, пол, серия, номер, орган и дата выдачи паспорта, дата и место рождения, адрес регистрации, номер СНИЛС, ИНН) Акционерному обществу «Группа компаний «ОСНОВА» (ИНН: 9715264590; ОГРН: 1167746614530 (далее - Оператор), зарегистрированному по адресу: 142702, Московская обл., Ленинский р-н, Видное г., рабочий поселок Бутово, территория жилой комплекс Бутово-Парк, дом </p>
<p>№ 18, корпус 1, пом. 59 с целью использования мною функционала Сервиса, расположенного на внутренних и внешних ресурсах Оператора, а также для целей указанных в Политике конфиденциальности Сервиса. </p>
<p>2. обработку моих персональных данных (фамилия, имя, отчество, номер мобильного телефона, пол, серия, номер, орган и дата выдачи паспорта, дата и место рождения, адрес регистрации, номер СНИЛС, ИНН) необходимых для предоставления с целью технического обслуживания Сервиса и выпуска Усиленной неквалифицированной электронной подписи Обществу с ограниченной ответственностью «Инновации в управлении кадрами» (ИНН 7801683312, ОГРН 1207800064339), зарегистрированному по адресу: 199397, Санкт-Петербург, Наличная ул., д. 44, корп./ст. 1с1, кв./оф. 805, Обществу с ограниченной ответственностью «АСТРАЛ-СОФТ» (ИНН: 4027145240; ОГРН: 1214000000092), зарегистрированному по адресу: 248000, обл. Калужская, г. Калуга, ул. Циолковского, д. 4, офис 211.</p>

<p>Настоящее согласие предоставляется на осуществление любого действия (операции) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, указанными выше, включая сбор, запись, анализ, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), идентификацию личности, обезличивание, блокирование, удаление, уничтожение персональных данных. </p>
<p>Настоящее согласие вступает в силу со дня его акцепта на Сервисе. Акцептом настоящего согласия признается использование учетной записи в Сервисе.</p>
<p>В случае использования мною Сервиса Оператора для кадрового документооборота в связи с наличием трудовых правоотношений, я предоставляю свое согласие на обработку персональных данных на срок действия трудовых правоотношений, а также после их прекращения, согласно действующему законодательству РФ.</p>
<p>В случае использования мною Сервиса Оператора в связи с оформлением гражданско-правовых правоотношений, я предоставляю свое согласие на обработку персональных данных, на срок 5 лет.</p>
<p>Я уведомлен(-а) о своём праве отозвать настоящее согласие в любое время. Отзыв производится по моему письменному заявлению Оператору в порядке, определенном законодательством Российской Федерации, в том числе путем направления такого отзыва по адресу электронной почты: <a href="mailto:dpers@gk-osnova.ru">dpers@gk-osnova.ru</a>, либо в письменной форме на адрес Оператора.</p>
  </AggreementContent>
</template>

<script>
import AggreementContent from '@/components/AggreementContent.vue'

export default {
  components: {
    AggreementContent
  }
}
</script>
