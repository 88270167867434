<template>
  <div class="container-wrapper">
    <slot />
  </div>
</template>

<style lang="scss">
.container-wrapper {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
}
</style>
