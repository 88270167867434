import api from '@/api'
import updateObject from '@/helpers/updateObject.js'
export default {
  state: () => ({
    document: null,
    documentFile: null,
    filter: {
      page: null,
      limit: null,
      offset: null,
      statuses: null,
      docTypes: [],
      sortField: null,
      sortDir: null,
      legals: []
    }
  }),
  getters: {
    getDocument (state) {
      return state.document
    },
    getDocumentFilter (state) {
      return state.filter
    },
    getDocumentFile (state) {
      return state.documentFile
    },
  },
  mutations: {
    setDocument (state, payload) {
      state.document = payload
    },
    setDocumentFile (state, payload) {
      state.documentFile = payload
    },
    setDocumentFilter (state, payload) {
      const filter = updateObject(state.filter, payload)
      state.filter = filter
    },
  },
  actions: {
    fetchDocument ({commit}, id) {
      const proms = []
      proms.push(api.documents.getDocument(id))
      return Promise.all(proms).then((data) => {
        if (data[0] && data[0].success) {
          commit('setDocument', data[0].data.document)
        }
      })
    },
    fetchTestDocument ({commit}, id) {
      const proms = []
      proms.push(api.documents.getTestDocument(id))
      return Promise.all(proms).then((data) => {
        if (data[0] && data[0].success) {
          commit('setDocument', data[0].data.document)
        }
      })
    },
    
    fetchPdf ({commit}, id) {
      const proms = []
      proms.push(api.documents.getDocumentPdf(id))
      return Promise.all(proms).then((data) => {
        if (data[0]) {
          commit('setDocumentFile', 'data:application/pdf;base64,' + data[0])
        }
      })
    },

    fetchTestPdf ({commit}, id) {
      const proms = []
      proms.push(api.documents.getTestDocumentPdf(id))
      return Promise.all(proms).then((data) => {
        if (data[0]) {
          commit('setDocumentFile', 'data:application/pdf;base64,' + data[0])
        }
      })
    },
    resetPdf ({commit}) {
      commit('setDocumentFile', null)
    }
  }
}
