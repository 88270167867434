<template>
  <div v-if="openned" class="modal-wrapper">
    <div class="modal-wrapper__back" @click="close"></div>
    <div class="modal-wrapper__body">
      <div class="modal-wrapper__header">
        <div class="modal-wrapper__title">
          {{ title }}
        </div>
        <button @click="close" class="modal-wrapper__close"><div class="modal-cross-icon" /></button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    openned: {
      type: Boolean,
      default: true
    },
    callbackCancel: {
      type: Function,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    return {
      close () {
        store.dispatch('closePopup')
        if (props.callbackCancel) {
          props.callbackCancel()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1000;
  &__back {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }
  &__header {
    min-height: 30px;
    position: relative;
    padding-right: 30px;
    margin-bottom: 20px;
  }
  &__title {
    line-height: 30px;
    font-size: 26px;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__body {
    position: relative;
    z-index: 1;
    width: 700px;
    max-height: 70%;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
  }

  .modal-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
