import { createWebHistory, createRouter } from "vue-router"
import prototypes from "@/utils/prototypes";
import routes from '@/router/routes.js'

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const TITLE = process.env.VUE_APP_TITLE;

router.beforeEach((to, from, next) => {
  prototypes.$setMeta({
    title: to.meta.title || TITLE,
  })
  if (!to.meta.guest) {
    const publicToken = window.localStorage.getItem('public_token')
    if (!publicToken) {
      return {name: 'Login'}
    }
  }
  next()
})

export default router
