import { sendAuthorized } from './common.js'
export default {
  getList: function (page = 1, limit, offset, statuses, docTypes, sortField, sortDir, legals) {
    return sendAuthorized({
      url: 'kedo/documents/filter-test',
      method: 'POST',
      toJson: true,
      body: {
        page: page,
        limit: limit,
        offset: offset,
        statuses: statuses,
        docTypes: docTypes,
        sortField: sortField,
        sortDir: sortDir,
        legals: legals
      }
    })
  },
  getDocument: function (id) {
    return sendAuthorized({
      url: 'kedo/documents/' + id,
      method: 'GET',
    })
  },
  getDocumentTypes: function () {
    return sendAuthorized({
      url: 'kedo/documents/types',
      method: 'GET',
    })
  },
  getDocumentLegalEntities: function () {
    return sendAuthorized({
      url: 'kedo/documents/legal-entities',
      method: 'GET',
    })
  },
  getDocumentPdf: function (id) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/pdf',
      method: 'GET',
    })
  },
  downloadArchive: function (id) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/archive',
      method: 'GET',
      isBlob: true
    }).then(data => {
      const file = URL.createObjectURL(data);
      window.location.assign(file);
    })
  },
  signDocument: function (id) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/sign',
      method: 'POST',
    })
  },
  conformDocument: function (id, signingRequestId, code) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/conform',
      method: 'POST',
      body: {
        signingRequestId: signingRequestId,
        code: code
      }
    })
  },
  rejectDocument: function (id, comment) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/reject',
      method: 'POST',
      body: {
        comment: comment
      }
    })
  },
  updateStatusTask: function (id, status, comment) {
    return sendAuthorized({
      url: 'kedo/documents/' + id + '/update-task',
      method: 'POST',
      body: {
        status: status,
        comment: comment
      }
    })
  },

  getTestDocument: function (id) {
    return sendAuthorized({
      url: 'kedo/debug-test-document/' + id,
      method: 'POST',
      body: {
        externalId: 'fc9e655d-d5a6-11ee-9140-00155d500322',
        clientId: '25383ec1-0ec6-485d-8ef8-5d246ad84f61'
      }
    })
  },

  getTestDocumentPdf: function (id) {
    return sendAuthorized({
      url: 'kedo/debug-test-pdf/' + id,
      method: 'POST',
      body: {
        externalId: 'fc9e655d-d5a6-11ee-9140-00155d500322',
        clientId: '25383ec1-0ec6-485d-8ef8-5d246ad84f61'
      }
    })
  },
  
}
