import { createStore } from 'vuex'
import auth from './modules/auth.js'
import user from './modules/user.js'
import popups from './modules/popups.js'
import document from './modules/document.js'

const store = createStore({
  modules: {
    auth,
    user,
    popups,
    document,
  }
})

export default store
