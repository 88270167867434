<template>
  <div class="the-header">
    <ContainerWrapper>
      <div class="the-header__wrapper">
        <div class="the-header__logo">
          <component :is="logoWrapper" :to="{'name': 'Documents'}">
            <img class="the-header__logo-img" src="@/assets/logo.svg">
          </component>
        </div>
        <div class="the-header__burger">
          <div @click="openned = !openned" class="the-header__burger-initials">{{initials}}</div>
          <div class="the-header__burger-menu" v-if="openned">
            <div class="the-header__burger-back" @click="openned = false" />
            <div class="the-header__burger-item">
              <button @click="toNqes">Сертификат ЭП</button>
            </div>
            <div class="the-header__burger-item">
              <button @click="logout">Выход</button>
            </div>
          </div>
        </div>
      </div>
    </ContainerWrapper>
  </div>
</template>

<script>
import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import ContainerWrapper from '@/components/ContainerWrapper.vue'
export default {
  components: {
    ContainerWrapper
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const lastName = computed(() => store.getters.getLastName)
    const firstName = computed(() => store.getters.getFirstName)
    const api = inject('apiMethods')
    const openned = ref(false)
    const logoWrapper = computed(() => {
      if (store.getters.getNQES == 'ACTIVE') {
        return 'router-link'
      }
      return 'div'
    })
    const initials = computed(() => {
      if (lastName.value && firstName.value) {
        return lastName.value[0] + firstName.value[0]
      }
      return ''
    })
    watch(route, () => {
      openned.value = false
    })
    return {
      initials,
      openned,
      logoWrapper,
      toNqes () {
        router.push({name: 'NQES'})
        openned.value = false
      },
      logout () {
        api.auth.logout().then(() => {
          window.localStorage.setItem('public_token', '')
          router.push({name: 'Login'})
        })
      }
    }
  }
}
</script>

<style lang="scss">
.the-header {
  background: #fff;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    width: 100%;
    align-items: center;
  }
  &__logo-img {
    display: block;
  }
  &__burger {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    background-color: #e3e3e3;
    cursor: pointer;
    &-initials {
      line-height: 40px;
      text-align: center;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    &-back {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &-menu {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 100;
      background-color: #fff;
      box-shadow: 0px 10px 10px 3px rgba(0,0,0,0.4);
    }
    &-item {
      white-space: nowrap;
      text-align: right;
      button {
        height: unset;
        border: 0;
        padding: 10px 20px;
      }
    }
  }
}
</style>
