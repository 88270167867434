import store from '@/store'
function getStatus(data) {
  if (data.deleted) {
    return 'deleted'
  }
  if (data.draft) {
    return 'draft'
  }
  if (data.rejected) {
    return 'rejected'
  }
  if (data.signingOrder == 'MANAGER_ONLY') {
    let watcher = null
    data.watchers.forEach(item => {
      if (store.getters.getEmployeesId.includes(item.employeeId)) {
        watcher = item
      }
    })
    if (watcher && watcher.seenDate) {
      return 'completed'
    } else {
      return 'in_process'
    }
  }
  let employee = null
  data.employees.forEach(item => {
    if (store.getters.getEmployeesId.includes(item.employeeId)) {
      employee = item
    }
  })
  if (employee) {
    if (employee.signedDate) {
      return 'signed'
    }
    return 'in_process'
  }
  return 'none'
}
function localizeStatus (data) {
  switch (data) {
    case ('deleted'):
      return 'Удален'
    case ('draft'):
      return 'Черновик'
    case ('rejected'):
      return 'Отклонен'
    case ('signed'):
      return 'Подписан'
    case ('none'):
      return 'На ознакомлении'
    case ('completed'):
      return 'Завершен'
    default:
      return 'В процессе'
  }
}
function getStatusLocalize(data) {
  const status = getStatus(data)
  return localizeStatus(status)
}

function getDocumentEmployee(data) {
  let out = null
  if (data.signingOrder == 'MANAGER_ONLY') {
    data.watchers.forEach(item => {
      if (store.getters.getEmployeesId.includes(item.employeeId)) {
        out = item
        return
      }
    })
  } else {
    data.employees.forEach(item => {
      if (store.getters.getEmployeesId.includes(item.employeeId)) {
        out = item
        return
      }
    })
  }
  return out
}

function getDate(val) {
  let date = ''
  if (val.getDate() < 10) {
    date += '0' + val.getDate()
  } else {
    date += val.getDate()
  }
  date += '.'
  if (val.getMonth() + 1 < 10) {
    date += '0' + (val.getMonth() + 1)
  } else {
    date += (val.getMonth() + 1)
  }
  date += '.'
  date += val.getFullYear()
  return date
}

function getTime(val) {
  let date = ''
  if (val.getHours() < 10) {
    date += '0' + val.getHours()
  } else {
    date += val.getHours()
  }
  date += ':'
  if (val.getMinutes() < 10) {
    date += '0' + val.getMinutes()
  } else {
    date += val.getMinutes()
  }
  return date
}

export default {
  getStatus,
  localizeStatus,
  getStatusLocalize,
  getDocumentEmployee,
  getDate,
  getTime
}
