import store from '@/store'
import router from '@/router'
const API_URL = process.env.VUE_APP_API_URL + '/api/v1/'

const toFormData = value => {
  const data = new FormData()

  Object.entries(value).forEach(([k, v]) => v !== undefined && data.append(k, v))

  return data
}

const commonHeaders = {
  'Access-Control-Allow-Origin': process.env.VUE_APP_API_URL,
  'Accept-Language': 'ru'
}
export const request = ({url, method = 'GET', headers = {}, body = null, isBlob = false, toJson = false}) => {
  let optHeaders = {
    ...commonHeaders,
    ...headers
  }
  let optBody = null
  if (body) {
    optBody = toFormData(body)
  }
  if (toJson) {
    optHeaders['Content-Type'] = 'application/json'
    optBody = JSON.stringify(body)
  }
  const options = {
    method,
    headers: {
      ...optHeaders
    },
    credentials: 'include',
    body: optBody
  }

  return fetch(
    API_URL + url,
    options
  )
  .then((res) => {
    return res.status === 401 ? refresh().then((data) => {
      if (!data.data.access_token) {
        if (!router.currentRoute.value.meta.guest) {
          router.push({name: 'Login'})
        }
        return res
      }
      store.dispatch('updateToken', data.data.access_token)
      headers['Authorization'] = 'Bearer ' + getPublicToken()
      return request({url, method, headers, body, isBlob, toJson})
    }) : res
  })
}

export const send = (params) => {
  return request(params)
  .then( data => {
    if (data.ok) {
      if (params.isBlob) {
        return data.blob()
      }
      if (data.headers.get("content-type") == 'application/json') {
        return data.json()
      }
      return data.text()
    }
    return { success: false }
  })
}

function refresh () {
  return send({
    url: 'auth/refresh',
    method: 'POST',
  })
}

function getPublicToken() {
  return store.getters.getPublicToken
}

export const sendAuthorized = ({url, method = 'GET', headers = {}, body = null, isBlob = false, toJson = false}) => {
  const authHeaders = {
    'Authorization': 'Bearer ' + getPublicToken(),
    ...headers
  }
  return send({url, method, headers: authHeaders, body, isBlob, toJson})
}


