export default function updateObject(obj, source) {
  const res = Object.assign({}, obj)
  const keys = Object.keys(obj)
  keys.forEach(key => {
    if (source[key] != undefined) {
      res[key] = source[key]
    }
  })
  return res
}
