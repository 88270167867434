<template>
  <div class="nqes-page page-bottom">
    <div class="nqes-page__wrapper">
      <ContainerWrapper>
        <div class="nqes-page__header">
          <PageControl :backLink="nqesState == 'ACTIVE' ? {name: 'Documents'} : null" :backLinkText="nqesState == 'ACTIVE'? 'Реестр документов' : null">
          </PageControl>
          <PageControl>
            <v-btn-toggle :mandatory="true" v-if="channelStatus" v-model="pageToggle" class="nqes-page__header-page-toggle">
              <UiButton value="0">Сертификат</UiButton>
              <UiButton value="1">Смена канала подписания</UiButton>
            </v-btn-toggle>
            <div class="nqes-page__state" v-if="nqesStateText">
              <span>{{ nqesStateText }}</span>
            </div>
            <UiButton :disabled="buttonCheckDisabled" v-if="buttonCheckShow" @click="checkNqes" >Проверить</UiButton>
            <UiButton :disabled="buttonSignControlDisabled" v-if="buttonSignControlShow" @click="cancelDocument">Отклонить</UiButton>
            <UiButton :disabled="buttonSignControlDisabled" v-if="buttonSignControlShow" @click="signDocument" >Подписать заявку</UiButton>
            <UiButton :disabled="true" v-if="inProccess" >В обработке</UiButton>
            <UiButton :disabled="buttonConformDisabled" v-if="buttonConformShow" @click="conformNQES" >Подписать</UiButton>
            <UiButton v-if="buttonDownloadShow" @click="download" >Скачать</UiButton>
          </PageControl>
        </div>
        <div class="nqes-page__body">
          <div class="nqes-page__body-nqes" v-show="pageToggle == '0'">
            <VuePdfEmbed v-if="fileNqes" :source="fileNqes" ref="nqesFileViewer" :width="1250" />
            <UiLoader class="nqes-page__loader" v-else />
          </div>
          <div class="nqes-page__body-channel" v-show="pageToggle != '0'">
            <VuePdfEmbed v-if="fileChannel" :source="fileChannel" ref="channelFileViewer" :width="1250"/>
            <UiLoader class="nqes-page__loader" v-else />
          </div>
        </div>
      </ContainerWrapper>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import UiButton from '@/components/ui/UiButton.vue'
import ContainerWrapper from '@/components/ContainerWrapper.vue'
import PageControl from '@/components/PageControl.vue'
import UiLoader from '@/components/ui/UiLoader.vue'
import VuePdfEmbed from 'vue-pdf-embed'
export default {
  components: {
    UiButton,
    ContainerWrapper,
    PageControl,
    UiLoader,
    VuePdfEmbed
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const fileNqes = ref(null)
    const fileChannel = ref(null)
    const isLoad = ref(false)
    const waitConform = ref(false)
    const api = inject('apiMethods')
    const nqesState = ref(null)
    const inProccess = ref(false)
    const nqesStateText = ref('')
    const pageToggle = ref('0')
    const channelStatus = ref(null)
    const nqesFileViewer = ref(null)
    const channelFileViewer = ref(null)

    const buttonCheckDisabled = computed(() => {
      if (pageToggle.value == '0') {
        return isLoad.value || waitConform.value
      }
      return false
    })
    const buttonCheckShow = computed(() => {
      if (pageToggle.value == '0') {
        return inProccess.value
      }
      return false
    })

    const buttonSignControlDisabled = computed(() => {
      return isLoad.value || waitConform.value
    })
    const buttonSignControlShow = computed(() => {
      if (pageToggle.value == '0') {
        return !inProccess.value && nqesState.value == 'USER_NOTIFIED_ABOUT_ISSUE_REQUEST'
      }
      return !inProccess.value && channelStatus.value && channelStatus.value.state == 'USER_NOTIFIED_ABOUT_CHANNEL_CHANGE_REQUEST'
    })

    const buttonConformDisabled = computed(() => {
      return isLoad.value || waitConform.value
    })
    const buttonConformShow = computed(() => {
      if (pageToggle.value == '0') {
        return !inProccess.value && nqesState.value == 'USER_NOTIFIED_ABOUT_CERTIFICATE'
      }
      return false
    })

    const buttonDownloadShow = computed(() => {
      return nqesState.value == 'ACTIVE' && pageToggle.value == '0'
    })

    function checkNqes () {
      api.auth.getUser().then(data => {
        nqesState.value = data.data.NQES
        nqesStateText.value = ''
        if (nqesState.value == 'NQES_ISSUE_REQUEST_CREATED') {
          nqesStateText.value = 'Ожидайте выпуск сертификата УНЭП'
          waitConform.value = false
          inProccess.value = true
        }
        if (nqesState.value == 'USER_NOTIFIED_ABOUT_CERTIFICATE' || nqesState.value == 'ACTIVE') {
          inProccess.value = false
        }
        if (nqesState.value == 'ISSUING_REJECTED') {
          nqesStateText.value = 'Ошибка в данных заявки'
        }
        if (nqesState.value == 'NQES_ISSUE_REQUEST_FAILED') {
          nqesStateText.value = 'Ошибка УЦ'
        }
        
        if (nqesState.value != 'ACTIVE' && nqesState.value != 'USER_NOTIFIED_ABOUT_CERTIFICATE') {
          api.nqes.getTaskFile().then(data => {
            fileNqes.value = 'data:application/pdf;base64,' + data
          })
        } else {
          api.nqes.getCertFile().then(data => {
            fileNqes.value = 'data:application/pdf;base64,' + data
          })
        }
      })
    }

    function checkChannel () {
      api.nqes.getChangeChannelTask().then(data => {
        channelStatus.value = data.data
        inProccess.value = false
        if (channelStatus.value) {
          const state = channelStatus.value.state
          let printFile = false
          if (state == 'PRINT_FORM_CREATED' || state == 'FINISHED') {
            printFile = true
          }
          api.nqes.getChangeChannelTaskFile(printFile).then(data => {
            fileChannel.value = 'data:application/pdf;base64,' + data
          })
        }
      })
    }
    function cancel() {
      isLoad.value = false
      waitConform.value = false
    }
    function conformDocument (code) {
      if (pageToggle.value == '0') {
        isLoad.value = true
        api.nqes.conformTask(code, isDebug.value).then(() => {
          store.dispatch('openPopup',
            {
              name: 'info',
              props: {
                title: 'Заявка отправлена',
                info: 'Заявка на выпуск сертификата в обработке. Проверьте его наличие после получения SMS.',
                callbackConform: () => {
                  isLoad.value = false
                  waitConform.value = false
                  inProccess.value = true
                }
              }
            }
          )
        }).finally(() => {
          isLoad.value = false
          setTimeout(() => {
            checkNqes()
          }, 3000)
        })
      } else {
        isLoad.value = true
        api.nqes.changeChannelConfirm(code).then(() => {
          store.dispatch('openPopup',
            {
              name: 'info',
              props: {
                title: 'Заявка отправлена',
                info: 'Заявка на смену канала подтверждена',
                callbackConform: () => {
                  isLoad.value = false
                  waitConform.value = false
                  inProccess.value = true
                }
              }
            }
          )
        }).finally(() => {
          isLoad.value = false
          setTimeout(() => {
            checkChannel()
          }, 3000)
        })
      }
    }
    function rejectDocument (comment) {
      isLoad.value = true
      if (pageToggle.value == '0') {
        api.nqes.rejectTask(comment, isDebug.value).then(() => {
        }).finally(() => {
          isLoad.value = false
          showNQESPage()
        })
      } else {
        api.nqes.changeChannelReject(comment, isDebug.value).then(() => {
        }).finally(() => {
          isLoad.value = false
          checkChannel()
        })
      }
    }
    const isDebug = ref(false)

    function showNQESPage () {
      checkNqes()
      const nqesChecker = setInterval(() => {
        if (nqesState.value != 'NQES_ISSUE_REQUEST_CREATED') {
          clearInterval(nqesChecker)
        }
        checkNqes()
      }, 5000)
    }

    function showChannelPage () {
      checkChannel()
      const nqesChecker = setInterval(() => {
        if (!channelStatus.value || channelStatus.value.state != 'USER_NOTIFIED_ABOUT_CHANNEL_CHANGE_REQUEST' || channelStatus.value.state != 'USER_CONFIRMED') {
          clearInterval(nqesChecker)
        }
        checkChannel()
      }, 5000)
    }

    onMounted(() => {
      if (route.query.toggle) {
        pageToggle.value = route.query.toggle
      }
      showChannelPage()
      showNQESPage()
    })
    return {
      nqesState,
      fileNqes,
      fileChannel,
      isLoad,
      conformDocument,
      rejectDocument,
      cancel,
      waitConform,
      checkNqes,
      inProccess,
      isDebug,
      nqesStateText,
      pageToggle,
      buttonCheckDisabled,
      buttonCheckShow,
      buttonSignControlDisabled,
      buttonSignControlShow,
      buttonConformDisabled,
      buttonConformShow,
      buttonDownloadShow,
      channelStatus,
      nqesFileViewer,
      channelFileViewer,
      signDocument () {
        if (pageToggle.value == '0') {
          isLoad.value = true
          api.nqes.signTask(isDebug.value).then((data) => {
            store.dispatch('openPopup',
              {
                name: 'conform',
                props: {
                  withCode: true,
                  title: 'Подтверждение заявки на выпуск УНЭП',
                  callbackConform: conformDocument,
                  callbackCancel: cancel
                }
              }
            )
            waitConform.value = true
          }).finally(() => {
            isLoad.value = false
          })
        } else {
          isLoad.value = true
          api.nqes.changeChannelCodeRequest(isDebug.value).then((data) => {
            store.dispatch('openPopup',
              {
                name: 'conform',
                props: {
                  withCode: true,
                  title: 'Подтверждение заявки на смену канала подписания УНЭП',
                  callbackConform: conformDocument,
                  callbackCancel: cancel
                }
              }
            )
            waitConform.value = true
          }).finally(() => {
            isLoad.value = false
          })
        }
      },
      cancelDocument () {
        isLoad.value = true
        waitConform.value = true
        store.dispatch('openPopup',
          {
            name: 'reject',
            props: {
              title: 'Укажите причину отклонения',
              callbackConform: rejectDocument,
              callbackCancel: cancel
            }
          }
        )
      },
      conformNQES () {
        isLoad.value = true
        store.dispatch('openPopup',
          {
            name: 'conform',
            props: {
              withCode: false,
              title: 'Подтверждение выпуска УНЭП',
              callbackConform: () => {
                api.nqes.conformCert(isDebug.value).then((data) => {
                  waitConform.value = true
                  store.dispatch('openPopup',
                    {
                      name: 'info',
                      props: {
                        title: 'Выпуск УНЭП подтвержден',
                        info: 'В ближайшее время сертификат станет доступен',
                        callbackConform: () => {
                          isLoad.value = false
                          waitConform.value = false
                        }
                      }
                    }
                  )
                }).catch(() => {
                  store.dispatch('openPopup',
                    {
                      name: 'info',
                      props: {
                        title: 'Ошибка выпуска сертификата',
                        info: '',
                        callbackConform: () => {
                          isLoad.value = false
                          waitConform.value = false
                        }
                      }
                    }
                  )
                }).finally(() => {
                  const nqesChecker = setInterval(() => {
                    checkNqes()
                    if (nqesState.value == 'ACTIVE') {
                      clearInterval(nqesChecker)
                    }
                  }, 5000)
                })
              },
              callbackCancel: cancel
            }
          }
        )
        
      },
      download () {
        api.nqes.download()
      }
    }
  }
}
</script>

<style lang="scss">
.nqes-page {
  &__header {
    width: 100%;
    &-page-toggle {
      margin-right: auto;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }
  &__state {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}
</style>
