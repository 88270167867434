<template>
  <UiForm class="login-form" ref="formWrapper" @submit="(e) => submit(e)">
    <UiField
      label="Телефон"
      name="phone"
      v-model="formPhone"
      :disabled="showedCodeField || disabled"
      :error="errors.phone"
      mask="+7(###) ### ##-##"
    />
    <div v-if="showedCodeField" class="login-form__resend">
      <template v-if="timeout">
        Запросить код повторно через {{ timeout }} секунд
      </template>
      <template v-else>
        <UiButton variant="plain" @click="resend">Повторно отправить код</UiButton>
      </template>
    </div>
    <UiField
      v-if="showedCodeField"
      label="Код"
      name="smscode"
      :disabled="disabled"
      v-model="formCode"
      :error="errors.code"
    />
    <UiCheckbox
      :model-value="aggreementField"
      @update:model-value="x => aggreementField = x"
    >
      Я подтверждаю, что принимаю <router-link :to="{name: 'UserAgreement'}">Пользовательское соглашение</router-link>, условия <router-link :to="{name: 'ConfPolicy'}">Политики конфиденциальности</router-link> и даю <router-link :to="{name: 'PersonalDataPolicy'}">Cогласие на обработку персональных данных</router-link>
    </UiCheckbox>
    <UiButton
      class="w-100"
      type="submit"
      icon-after="mdi-arrow-right"
      :disabled="!aggreementField || disabledButton || disabled"
      @click="submit"
    >Войти</UiButton>
  </UiForm>
</template>

<script>
import { ref, watch, computed, inject, onMounted } from 'vue'
import UiField from '@/components/ui/UiField.vue'
import UiForm from '@/components/ui/UiForm.vue'
import UiButton from '@/components/ui/UiButton.vue'
import UiCheckbox from '@/components/ui/UiCheckbox.vue'

export default {
  components: {
    UiField,
    UiForm,
    UiButton,
    UiCheckbox
  },
  emits: ['submit', 'resend', 'submit-phone', 'submit-code'],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      default: {}
    }
  },
  
  setup(props, {emit}) {
    const showedCodeField = ref(false)
    const formWrapper = ref()
    const timeout = ref(0)
    const formPhone = ref('')
    const formCode = ref('')
    const interval = ref(null)
    const aggreementField = ref(false)
    const clearPhone = inject('clearPhone')
    onMounted(() => {
      const localAgreement = localStorage.getItem('login-aggreement')
      if (localAgreement) {
        aggreementField.value = Boolean(localAgreement)
      }
    })
    watch(aggreementField, (newVal) => {
      localStorage.setItem('login-aggreement', aggreementField.value)
    })
    watch(() => props.errors, (newVal) => {
      formWrapper.value.setErrors(newVal)
    })
    watch(formPhone, (newVal) => {
      formWrapper.value.updateError('phone', '')
    })
    watch(formCode, (newVal) => {
      formWrapper.value.updateError('code', '')
    })
    const disabledButton = computed(() => {
      if (formPhone.value.length < 12) {
        return true
      }
      if (showedCodeField.value && formCode.value.length < 4) {
        return true
      }
      return false
    })
    return {
      formWrapper,
      showedCodeField,
      formPhone,
      formCode,
      timeout,
      disabledButton,
      aggreementField,
      setTimeout: () => {
        timeout.value = 30
        interval.value = setInterval(() => {
          timeout.value -= 1
          if (timeout.value == 0) {
            clearInterval(interval.value)
          }
        }, 1000)
      },
      showCodeField: () => {
        showedCodeField.value = true
      },
      reset: () => {
        showedCodeField.value = false
        formPhone.value = ''
        formCode.value = ''
      },
      resend: () => {
        emit('resend', clearPhone(formPhone.value))
      },
      submit: (e) => {
        if (e) {
          e.preventDefault()
        }
        if (!aggreementField.value || disabledButton.value || props.disabled) {
          return
        }
        if (!showedCodeField.value) {
          emit('submit-phone', clearPhone(formPhone.value))
          return
        }
        emit('submit-code', formCode.value)
      },
    }
  }
}
</script>

<style lang="scss">
.login-form {
  &__resend {
    font-size: 14px;
    color: #6f6f6f;
    margin-bottom: 20px;
  }
}
</style>

