<template>
  <div class="document-error">
    Ошибка при загрузке документа.<br>
    Недостаточно прав для просмотра.
  </div>
</template>

<style lang="scss">
.document-error {
  padding: 60px 0;
}
</style>
