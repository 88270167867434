import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import api from './api'
import documentHelpers from './helpers/documentHelpers'
import store from './store'
import clearPhone from './helpers/clearPhone'
import { vMaska } from "maska"
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { ru } from 'vuetify/locale'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const vueApp = createApp(App)

const vuetify = createVuetify({ 
  components, 
  directives, 
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  locale: {
    locale: 'ru',
    messages: { ru }
  },
  theme: {
    defaultTheme: 'custom',
    themes: {
      custom: {
        colors: {
          primary: '#142654'
        }
      }
    }
  }
})

vueApp.directive("maska", vMaska)
vueApp.mixin(mixins)
vueApp.use(vuetify)
vueApp.use(router)
vueApp.use(store)
vueApp.provide('apiMethods', api)
vueApp.provide('documentHelpers', documentHelpers)
vueApp.provide('clearPhone', clearPhone)
vueApp.mount('#app')
