export default {
  state: () => ({
    openned: null,
    props: {}
  }),
  getters: {
    getOpenned (state) {
      return state.openned
    },
    getProps (state) {
      return state.props
    },
  },
  mutations: {
    setOpenned (state, payload) {
      state.openned = payload
    },
    setProps (state, payload) {
      state.props = payload
    },
  },
  actions: {
    openPopup ({commit}, {name, props}) {
      commit('setProps', props)
      commit('setOpenned', name)
    },
    closePopup({commit}) {
      commit('setOpenned', null)
      commit('setProps', null)
    }
  }
}
