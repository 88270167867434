<template>
  <ModalWrapper :title="title" :callbackCancel="callbackConform">
    <div class="modal-info">
      <div class="modal-info__text">
        {{ info }}
      </div>
      <div class="modal-buttons">
        <UiButton
          @click="() => {callbackConform(); close()}"
        >Подтвердить</UiButton>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import ModalWrapper from '@/components/popups/ModalWrapper.vue'
import UiButton from '@/components/ui/UiButton.vue'
export default {
  components: {
    ModalWrapper,
    UiButton
  },
  props: {
    info: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    callbackConform: {
      type: Function,
      default: null
    },
  },
  setup () {
    const formCode = ref('')
    const store = useStore()
    return {
      formCode,
      close () {
        store.dispatch('closePopup')
      }
    }
  }
}
</script>

<style lang="scss">
.modal-info {
  &__text {
    padding: 8px 0;
  }
}
</style>
