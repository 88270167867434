export default {
  methods: {
    formatDate (x) {
      if (!x) {
        return ''
      }
      const date = new Date(x)
      const day = (date.getDate() < 10) ? '0' + date.getDate() : String(date.getDate())
      const dateMonth = date.getMonth() + 1
      const month = (dateMonth < 10) ? '0' + dateMonth : String(dateMonth)
      const year = date.getFullYear()
      return day + '.' + month + '.' + year
    }
  }
}
