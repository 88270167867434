<template>
  <div class="default-layout">
    <TheHeader />
    <router-view />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
export default {
  components: {
    TheHeader
  }
}
</script>

<style lang="scss">
.default-layout {
  min-height: 100vh;
  background: #ededfb;
}
</style>
