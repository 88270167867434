<template>
  <v-table
    fixed-header
    :key="loaded"
    v-if="list"
    class="document-list"
  >
    <DocumentFilter :length="list.length"/>
    <tbody>
      <tr
        v-for="(item, i) in list"
        :key="i"
      >
        <td><div class="document-list__icon"><DocumentIcon :icon="getStatus(item)"/></div></td>
        <td>
          <router-link :to="{name: 'DocumentInner', params: {id: item.id}}">
            {{ item.number || 'Без номера' }}
          </router-link>
        </td>
        <td>
          <router-link :to="{name: 'DocumentInner', params: {id: item.id}}">
            {{ item.type? item.type.name : '' }}
          </router-link>
        </td>
        <td>{{ formatDate(item.date) }}</td>
        <td>{{ item.legalEntity ? item.legalEntity.shortName : '' }}</td>
        <td>{{ formatDate(item.createdDate) }}</td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
import { ref, nextTick, watch, inject } from 'vue'
import DocumentIcon from '@/components/DocumentIcon.vue'
import DocumentFilter from '@/components/DocumentFilter.vue'
export default {
  components: {
    DocumentIcon,
    DocumentFilter
  },
  props: {
    list: {
      type: Array,
      default: null
    },
    page: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const loaded = ref(false)
    const documentHelpers = inject('documentHelpers')

    watch(() => props.list, () => {
      nextTick(() => {
        loaded.value = true
      })
    })
    return {
      loaded,
      getStatus(item) {
        return documentHelpers.getStatus(item)
      }
    }
  }
}
</script>

<style lang="scss">
.document-list {
  &__icon {
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
</style>
